import React, { useState, useEffect } from 'react';
import { useWallet } from '../WalletContext';
import { ethers } from 'ethers';
import Candles from '../abis/Candles.json';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import { MoonsContractAddress } from './ContractAddress.js';
import styled from 'styled-components';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { BorderRad, Colors, Fonts, Transitions } from '../styles/global/styles';

const MintPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  color: black;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const VestingInteraction = () => {
    const { provider, account } = useWallet();
    const [contract, setContract] = useState(null);
    const [userTokenCount, setUserTokenCount] = useState(0);
    const [mintCount, setMintCount] = useState(1);
    const [totalMinted, setTotalMinted] = useState(0);
    const [showMintPopup, setShowMintPopup] = useState(false);
    const [showInfoPopup, setShowInfoPopup] = useState(false);
    const [totalMinted1x, setTotalMinted1x] = useState(0);
    const [totalMinted5x, setTotalMinted5x] = useState(0);

    const MINT_5X_PRICE = 5; // 5 AVAX
    const MINT_PRICE = 1; // 1 AVAX

    useEffect(() => {
        if (provider) {
            const newContract = new ethers.Contract(MoonsContractAddress, Candles.abi, provider);
            setContract(newContract);
        }
    }, [provider]);

    useEffect(() => {
        const fetchTokenCount = async () => {
            if (contract && account) {
                const tokenCountResult = await contract.balanceOf(account);
                setUserTokenCount(tokenCountResult.toString());
            }
        };

        const fetchTotalMinted = async () => {
            if (contract) {
                const totalMinted1xResult = await contract.totalMinted_1x();
                const totalMinted5xResult = await contract.totalMinted_5x();
                setTotalMinted1x(totalMinted1xResult.toString());
                setTotalMinted5x(totalMinted5xResult.toString());
            }
        };

        fetchTokenCount();
        fetchTotalMinted();
    }, [contract, account]);


    useEffect(() => {
        const fetchTotalMinted = async () => {
            if (contract) {
                const totalMinted1xResult = await contract.totalMinted_1x();
                setTotalMinted1x(totalMinted1xResult.toString());

                // Update totalMinted by adding totalMinted1x and totalMinted5x
                const total = totalMinted1xResult;
                setTotalMinted(total.toString());
            }
        };

        fetchTotalMinted();
    }, [contract]);

    const handleMint = async () => {
        if (contract && account) {
            const signer = provider.getSigner();
            const contractWithSigner = contract.connect(signer);

            const totalAmount = ethers.utils.parseEther((MINT_PRICE * mintCount).toString());

            const overrides = {
                gasLimit: 5000000,
                value: totalAmount
            };

            await contractWithSigner.mint(overrides);
            setShowMintPopup(true);
            setTimeout(() => setShowMintPopup(false), 3000);
            setUserTokenCount((prev) => Number(prev) + mintCount);
            setTotalMinted1x((prev) => Number(prev) + mintCount);


        }
    };

    const handleMint5x = async () => {
        if (contract && account) {
            const signer = provider.getSigner();
            const contractWithSigner = contract.connect(signer);

            const mint5xCount = 1;
            const totalAmount = ethers.utils.parseEther((MINT_5X_PRICE).toString());

            const overrides = {
                gasLimit: 5000000,
                value: totalAmount
            };

            await contractWithSigner.mint5x(overrides);
            setShowMintPopup(true);
            setTimeout(() => setShowMintPopup(false), 5000);
            setUserTokenCount((prev) => Number(prev) + mint5xCount);
            setTotalMinted5x((prev) => Number(prev) + mint5xCount);

        }
    };

    return (
        <Interaction>
            <div style={{ marginTop: '-55px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div>Mint Price: 1 Avax<InfoLink onClick={() => setShowInfoPopup(true)}>i</InfoLink>
                </div>
                {userTokenCount && <div>Number of Moons: {userTokenCount}</div>}
                {showInfoPopup && (
                    <InfoPopup style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p>
                            Minting a Moon will provide between 14-28 vested SOULs harvested at a rate of 1 per day.
                        </p>
                        <InfoButton onClick={() => setShowInfoPopup(false)}>Close</InfoButton>
                    </InfoPopup>
                )}
                <div>Total Minted: {totalMinted1x} / 700</div>
           
                <MintButton onClick={handleMint}>Mint a Moon</MintButton>
                  {/**    <div>Total Minted 5x: {totalMinted5x} / 100</div>*/}
             {/**    <Mint5Button onClick={handleMint5x}>Mint 5x Moon</Mint5Button>*/}

            </div>
        </Interaction>
    );
};

export default VestingInteraction;

const Interaction = styled.div`
  position: relative;
margin-botton: 200px;


`;

const InfoPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  color: black;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const InfoLink = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  line-height: 14px;
  cursor: pointer;
`;




export const MintButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 140px;
  min-width: 140px;
  height: 40px;
  //font-family: ${Fonts.MedievalSharp};
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: ${Transitions.all};
  margin-top: 65px;
     @media (max-width: 768px) {
  margin-top: 15px;
  height: 60px;

  }

   &:hover,
  &:focus {
    background-color: #A8A9AD;
    color: #000000;
     border: 2px solid #ffffff;
  border-radius: ${BorderRad.m};
 
  }`

export const Mint5Button = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 220px;
  min-width: 160px;
  height: 40px;
  //font-family: ${Fonts.MedievalSharp};
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: ${Transitions.all};
  margin-top: 65px;
    @media (max-width: 768px) {
  margin-top: 15px;
  }
  

   &:hover,
  &:focus {
    background-color: #d4af37;
    color: #000000;
     border: 2px solid #ffffff;
  border-radius: ${BorderRad.m};
  }`

export const InfoButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 140px;
  min-width: 140px;
  height: 40px;
  //font-family: ${Fonts.MedievalSharp};
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: ${Transitions.all};
  margin-top: 20px;
  

  &:hover,
  &:focus {
    background-color: #FFBFA9;
    color: #000000;
     border: 2px solid #FFBFA9;
  border-radius: ${BorderRad.m};
  }`