import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ethers } from 'ethers';
import { useWallet } from '../WalletContext';
import { TraderJoeRouterAddress, LPAddress } from './ContractAddress';
import TraderJoeRouterABI from '../abis/TraderJoeRouterABI.json';
import LPABI from '../abis/LP.json';
import { MUNContractAddress, WAVAX } from './ContractAddress';
import MUN_ABI from '../abis/MUN.json';

const SwapInterface = () => {
  const { provider, account } = useWallet();
  const [desiredMunInput, setDesiredMunInput] = useState('');
  const [requiredAvaxInput, setRequiredAvaxInput] = useState('');
  const [avaxBalance, setAvaxBalance] = useState('');
  const [munBalance, setMunBalance] = useState('');
  const [munPrice, setMunPrice] = useState('');


  useEffect(() => {
    const loadBalances = async () => {
      if (provider && account) {
        const balance = await provider.getBalance(account);
        setAvaxBalance(ethers.utils.formatEther(balance));
        const munContract = new ethers.Contract(MUNContractAddress, MUN_ABI.abi, provider);
        const munBalance = await munContract.balanceOf(account);
        setMunBalance(ethers.utils.formatEther(munBalance));
      }
    };
    loadBalances();
  }, [provider, account]);

  const handleApproveToken = async () => {
    if (!provider || !account) return;
    const munContract = new ethers.Contract(MUNContractAddress, MUN_ABI.abi, provider.getSigner());
    try {
      const tx = await munContract.approve(TraderJoeRouterAddress, ethers.constants.MaxUint256);
      await tx.wait();
      alert("Token approval successful!");
    } catch (error) {
      alert("Error approving tokens: " + error.message);
    }
  };

  const handleSwap = async () => {
    if (!provider || !account) return;
  
    const traderJoeRouter = new ethers.Contract(TraderJoeRouterAddress, TraderJoeRouterABI, provider.getSigner());
    const path = [WAVAX, MUNContractAddress];
  
    try {
      if (desiredMunInput) {
        // Convert the desired MUN amount to Wei using the token's decimal places
        const desiredMunWei = ethers.utils.parseUnits(desiredMunInput, 18); // Assuming MUN has 18 decimals
        // You should fetch the actual required AVAX amount again here to ensure accuracy and avoid errors
        const amountsIn = await traderJoeRouter.getAmountsIn(desiredMunWei, path);
        const requiredAvaxWei = amountsIn[0];
  
        const tx = await traderJoeRouter.swapExactAVAXForTokens(
          0, // amountOutMin set to 0 for simplicity, should be calculated based on slippage
          path,
          account,
          Math.floor(Date.now() / 1000) + 60 * 20,
          { value: requiredAvaxWei }
        );
        await tx.wait();
        alert("Swap AVAX for MUN successful!");
      }
    } catch (error) {
      alert("Error swapping tokens: " + error.message);
    }
  };
  

  const updateRequiredAvax = async (munAmount) => {
    if (!provider || !munAmount) return;
    const traderJoeRouter = new ethers.Contract(TraderJoeRouterAddress, TraderJoeRouterABI, provider);
    const desiredMunWei = ethers.utils.parseUnits(munAmount, 'ether');
    const path = [WAVAX, MUNContractAddress];
    try {
      const amountsIn = await traderJoeRouter.getAmountsIn(desiredMunWei, path);
      const requiredAvaxWei = amountsIn[0];
      setRequiredAvaxInput(ethers.utils.formatEther(requiredAvaxWei));
    } catch (error) {
      console.error("Error calculating required AVAX:", error);
      setRequiredAvaxInput('');
    }
  };

 
  
  useEffect(() => {
    const fetchCurrentPriceOfMUN = async () => {
      try {
        const lpContract = new ethers.Contract(LPAddress, LPABI, provider);
        const [reserve0, reserve1] = await lpContract.getReserves();
        
        // Convert reserves to a format we can work with (BigNumber to String for arithmetic)
        const reserveMUN = ethers.utils.formatUnits(reserve0, 'ether');
        const reserveAVAX = ethers.utils.formatUnits(reserve1, 'ether');
      
        // The price of MUN in terms of AVAX is the amount of AVAX per one MUN
        const price = (parseFloat(reserveAVAX) / parseFloat(reserveMUN)).toString();
        setMunPrice(price);
      } catch (error) {
        console.error("Error fetching current MUN price:", error);
        setMunPrice('N/A');
      }
    };
    
    fetchCurrentPriceOfMUN();
  }, [provider]);
  

  return (
    <SwapContainer>
      <BalanceDisplay>AVAX Balance: {avaxBalance}</BalanceDisplay>
      <BalanceDisplay>MUN Balance: {munBalance}</BalanceDisplay>
      <BalanceDisplay>MUN Price: {munPrice} AVAX</BalanceDisplay> {/* Display MUN price */}

      <InputLabel>Desired MUN to Buy:</InputLabel>
      <InputField
        type="text"
        value={desiredMunInput}
        onChange={(e) => {
          setDesiredMunInput(e.target.value);
          updateRequiredAvax(e.target.value);
        }}
        placeholder="Enter desired MUN amount"
      />
      <InputLabel>Required AVAX:</InputLabel>
      <InputField
        type="text"
        value={requiredAvaxInput}
        placeholder="Required AVAX amount"
        readOnly
      />
      <SwapButton onClick={handleApproveToken}>Approve MUN</SwapButton>
      <SwapButton onClick={handleSwap}>Swap</SwapButton>
    </SwapContainer>
  );
};

// Styled Components
const SwapContainer = styled.div`
  margin-top: 20px;
`;

const BalanceDisplay = styled.p`
  color: #fff;
  font-size: 0.9em;
`;

const InputLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  color: white;
`;

const InputField = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
`;

const SwapButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  margin-right: 10px;
`;

export default SwapInterface;
