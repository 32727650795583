import React, { useState, useEffect } from 'react';
import './css/Page2.css';
import styled from 'styled-components';
import { useWallet } from '../WalletContext';

const PortalCerts = () => {
    const { account } = useWallet();
    const [loading, setLoading] = useState(false);
    const [displayedContent, setDisplayedContent] = useState(null);
    const [isGalleryVisible, setIsGalleryVisible] = useState(true);

    useEffect(() => {
        if (account) {
            setLoading(true);
            const timer = setTimeout(() => {
                setLoading(false);
                setDisplayedContent(
                    <>
                        <TitleBox className="title-container">
                            <h1>Arcanum Early Access</h1>
                        </TitleBox>

                        <StyledDivVesting>


                            <VestingBox>
                  

                            </VestingBox>


                        </StyledDivVesting>

                    </>
                );
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [account]);

    return (
        <>
            {loading ? (
                <Loader>Loading...</Loader>
            ) : account ? (
                displayedContent
            ) : (
                <ConnectWalletMessage>Please Connect Wallet to Certify Portal</ConnectWalletMessage>
            )}
        </>
    );
};

export default PortalCerts;


const StyledDivVesting = styled.div`
     width: 65%;
    height: 570px;
    /* Add 600px to the existing height */
    margin: 0px auto 0;
    /* Add 50px to the top margin */
    padding: 20px;
    border: 1px solid #ffffff;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.9);
    background-color: #000000;
    color: #c3c3c3;


  @media (max-width: 768px) {
         width: 95%;
 border: 0px solid #ffffff;
     background-color: transparent;
     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.0);

  }
`;

const TitleBox = styled.div`
  {
    color: white;
    margin-top: 120px;
  }
`;

const VestingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-left: 20px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
      margin-left: 0px;

  }
`;



const VestingInteractionDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 20px;
  margin-top: 40px;

  ${({ isGalleryVisible }) =>
        !isGalleryVisible &&
        `
          margin-top: 0px;

    align-items: center;
    justify-content: center;
  `}

  @media (max-width: 768px) {
      margin-right: 00px;

    min-width: 60px;
    align-items: center;
  }
`;



const ConnectWalletMessage = styled.div`
display: flex;
justify - content: center;
align - items: center;
height: calc(100vh - 90px); // adjust this value based on your header height
font - size: 24px;
font - weight: bold;
`;


const Loader = styled.div`
display: flex;
justify - content: center;
align - items: center;
height: calc(100vh - 90px); // adjust this value based on your header height
font - size: 24px;
font - weight: bold;
`;


