import React, { useState, useEffect } from 'react';
import { useWallet } from '../WalletContext';
import { ethers } from 'ethers';
import Moons from '../abis/Moons.json';
import MUNStakingABI from '../abis/MUNStaking.json';
import MUN from "../abis/MUN.json"
import { contractAddress, LpTokenRecipientAddress, MUNContractAddress, MoonsContractAddress } from './ContractAddress.js';
import styled, { keyframes } from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { BorderRad, Colors, Fonts, Transitions } from '../styles/global/styles';
import VestingClaim from './MOONSVestingClaim';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import MunContractABI from "../abis/MUN.json"; // Replace with your actual MunContract ABI
import { Tabs, Tab, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';



function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    tabs: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  }));
  


const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;



const MAX_UINT256 = ethers.constants.MaxUint256; // Define maximum uint256 value for max approval
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const StakingGallery = () => {
    const { provider, account } = useWallet();
    const [contract, setContract] = useState(null);
    const [stakingContract, setStakingContract] = useState(null);
    const [stakeAmount, setStakeAmount] = useState('');
    const [isStaking, setIsStaking] = useState(false);
    const [ownedTokens, setOwnedTokens] = useState([]);
    const [selectedTokenId, setSelectedTokenId] = useState(null);
    const [vestedTokens, setVestedTokens] = useState({});
    const [claimedTokens, setClaimedTokens] = useState({});
    const [claimablePayouts, setClaimablePayouts] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showClaimPopup, setShowClaimPopup] = useState(false);
    const [tokenImages, setTokenImages] = useState({});
    const [loadedImages, setLoadedImages] = useState({});
    const [tokenContract, setTokenContract] = useState(null);
    const [munBalance, setMunBalance] = useState('0');
    const [stakedAmount, setStakedAmount] = useState('0');
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [unstakeAmount, setUnstakeAmount] = useState('');
    const [pendingRewards, setPendingRewards] = useState('0');
    const [stakingInfo, setStakingInfo] = useState({ startTime: 0, amountStaked: 0, rewardRatePerSecond: 0 });
    const [intervalId, setIntervalId] = useState(null); // Add this state variable
    const [hasApprovedMax, setHasApprovedMax] = useState(false); // New state to track max approval
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // new state for severity
    const [stakedBalance, setStakedBalance] = useState('0');

    const [tokenCounts, setTokenCounts] = useState({}); // Object to store token counts
    const [activeTab, setActiveTab] = useState('stake'); // Add state to control active tab
    const [allImagesLoaded, setAllImagesLoaded] = useState(false); // New state to track all images
    
    const [selectedTokenDetails, setSelectedTokenDetails] = useState(null);
  





    
    useEffect(() => {
        if (!provider) return;
    
        const initContracts = async () => {
            const signer = provider.getSigner();
    
            const newTokenContract = new ethers.Contract(MUNContractAddress, MUN.abi, signer);
            setTokenContract(newTokenContract);
    
            const newStakingContract = new ethers.Contract(LpTokenRecipientAddress, MUNStakingABI.abi, signer);
            setStakingContract(newStakingContract);

            const newContract = new ethers.Contract(MoonsContractAddress, Moons.abi, provider);
            setContract(newContract);
    
            // Additional initial setup can go here (e.g., fetching balances, setting up listeners)
        };
    
        initContracts();
    }, [provider]);


    useEffect(() => {
        const interval = setInterval(() => {
          updateRewards(); // Call updateRewards every interval
        }, 10000); // Update every 10 seconds, adjust as needed
      
        return () => clearInterval(interval); // Clear interval on component unmount
      }, [stakingContract, account]);

 
    const fetchStakedBalance = async () => {
        if (!stakingContract || !account) return;
      
        try {
          const userStake = await stakingContract.stakes(account);
          // Access the 'amount' property from the returned struct
          const stakedAmount = ethers.utils.formatUnits(userStake.amount, 18);
          console.log(`Fetched staked amount: ${stakedAmount}`);
          setStakedBalance(stakedAmount);
        } catch (error) {
          console.error('Failed to fetch staked balance:', error);
        }
      };
      
      useEffect(() => {
        const fetchStakedBalance = async () => {
          if (stakingContract && account) {
            try {
              const userStake = await stakingContract.stakes(account);
              const stakedAmount = ethers.utils.formatUnits(userStake.amount, 18); // Assuming 18 decimal places
              console.log("Staked Amount: ", stakedAmount); // Check if this logs the expected amount
              setStakedBalance(stakedAmount);
            } catch (error) {
              console.error("Failed to fetch staked balance: ", error);
            }
          }
        };
      
        fetchStakedBalance();
      }, [stakingContract, account]);
    



    useEffect(() => {
        if (!stakingContract || !account) return;
      
        const onStakeAdded = (user, amount) => {
          if (user.toLowerCase() === account.toLowerCase()) {
            console.log(`Stake Added: ${amount.toString()}`);
            // Fetch the new staked amount and update state
            fetchStakedBalance();
          }
        };
      
        // Listen for StakeAdded events where the user is the current account
        stakingContract.on(stakingContract.filters.StakeAdded(account), onStakeAdded);
      
        // Cleanup the event listener when the component unmounts
        return () => {
          if (stakingContract) {
            stakingContract.off('StakeAdded', onStakeAdded);
          }
        };
      }, [stakingContract, account]);


      const [specialTokens, setSpecialTokens] = useState({}); // Tracks tokens with "color" attribute as "united"

const fetchOwnedTokens = async () => {
  if (!tokenContract || !account) return;

  try {
    const tokenIds = await tokenContract.getOwnedTokenIds(account);
    console.log("Owned Token IDs:", tokenIds);

    const tokenCountsObj = {};
    const tokenImagesObj = {};
    let initialLoadedImages = {};
    let specialTokensTemp = {};

    for (const tokenId of tokenIds) {
        try {
          const metadataUri = await tokenContract.tokenURI(tokenId);
          const response = await fetch(metadataUri);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const metadata = await response.json();
          const imageUrl = metadata.image;
      
          initialLoadedImages[imageUrl] = false;
          tokenCountsObj[imageUrl] = (tokenCountsObj[imageUrl] || 0) + 1;
          tokenImagesObj[imageUrl] = imageUrl;
      
          // Check if metadata and metadata.attributes exist before accessing attributes
          if (metadata && metadata.attributes) {
            const colorAttribute = metadata.attributes.find(attr => attr.trait_type === "color" && attr.value === "united");
            if (colorAttribute) {
              specialTokensTemp[tokenId] = { imageUrl, metadata };
            }
          }
        } catch (error) {
          console.error("Error fetching metadata for token ID:", tokenId.toString(), error);
        }
      }

    setTokenCounts(tokenCountsObj);
    setTokenImages(tokenImagesObj);
    setLoadedImages(initialLoadedImages);
    setSpecialTokens(specialTokensTemp); // Update state with special tokens
  } catch (error) {
    console.error("Failed to fetch owned tokens:", error);
  }
};

    const handleImageLoad = (imageUrl) => {
        setLoadedImages(prev => {
            const newLoadedImages = { ...prev, [imageUrl]: true };
            const allLoaded = Object.values(newLoadedImages).every(isLoaded => isLoaded);
            if (allLoaded) {
                // Only update the allImagesLoaded state if all images are loaded
                setAllImagesLoaded(true);
            }
            return newLoadedImages;
        });
    };
    
    
    useEffect(() => {
        fetchOwnedTokens();
    }, [tokenContract, account]); 


 
  
    useEffect(() => {
      const updateTokensOnTransfer = async () => {
        if (!provider || !account) return;
  
        provider.on('block', () => {
          fetchOwnedTokens();
        });
  
        return () => {
          provider.removeAllListeners('block');
        };
      };
  
      updateTokensOnTransfer();
    }, [account, provider]);
  
    


   
      

    useEffect(() => {
        const checkApprovalStatus = async () => {
            if (tokenContract && stakingContract && account) {
                const allowance = await tokenContract.allowance(account, LpTokenRecipientAddress);
                if (allowance.eq(MAX_UINT256)) {
                    setHasApprovedMax(true);
                }
            }
        };

        checkApprovalStatus();
    }, [tokenContract, stakingContract, account]); // Add this useEffect hook to check approval status


    

useEffect(() => {
    const setupEventListeners = () => {
        if (!stakingContract || !account) return;

        const stakeAddedListener = stakingContract.filters.StakeAdded(account);
        stakingContract.on(stakeAddedListener, (user, amount) => {
            console.log(`Stake Added: User ${user} staked ${ethers.utils.formatUnits(amount, 'ether')} SOUL`);
            fetchOwnedTokens(); // Update tokens when a stake is added
        });

        const stakeWithdrawnListener = stakingContract.filters.StakeWithdrawn(account);
        stakingContract.on(stakeWithdrawnListener, (user, amount) => {
            console.log(`Stake Withdrawn: User ${user} withdrew ${ethers.utils.formatUnits(amount, 'ether')} SOUL`);
            fetchOwnedTokens(); // Update tokens when a stake is withdrawn
        });

        const rewardClaimedListener = stakingContract.filters.RewardClaimed(account);
        stakingContract.on(rewardClaimedListener, (user, amount) => {
            console.log(`Reward Claimed: User ${user} claimed ${ethers.utils.formatUnits(amount, 'ether')} SOUL`);
            fetchOwnedTokens(); // Update tokens when a reward is claimed
        });

        return () => {
            // Clean up the event listeners when the component unmounts
            stakingContract.removeAllListeners(stakeAddedListener);
            stakingContract.removeAllListeners(stakeWithdrawnListener);
            stakingContract.removeAllListeners(rewardClaimedListener);
        };
    };

    setupEventListeners();
}, [stakingContract, account, fetchOwnedTokens]); // Make sure to include fetchOwnedTokens in the dependency array if it's defined outside this useEffect


  

const updateRewards = async () => {
    if (!stakingContract || !account) return;
  
    try {
      const pendingRewards = await stakingContract.getPendingRewards(account);
      setPendingRewards(ethers.utils.formatUnits(pendingRewards, 18)); // Assuming 18 decimal places
    } catch (error) {
      console.error("Error fetching pending rewards:", error);
    }
  };
    








    useEffect(() => {
        const fetchMunBalance = async () => {
            if (tokenContract && account) {
                const balance = await tokenContract.balanceOf(account);
                setMunBalance(ethers.utils.formatUnits(balance, 18));
            }
        };

        fetchMunBalance();

        // Listen for the "Transfer" event from the MUN token contract
        if (tokenContract) {
            tokenContract.on("Transfer", (from, to, amount) => {
                // Update the MUN balance when a transfer event occurs
                fetchMunBalance();
            });
        }

        return () => {
            // Remove the event listener when the component unmounts
            if (tokenContract) {
                tokenContract.off("Transfer");
            }
        };
    }, [tokenContract, account]);






  








    const fetchTokens = async () => {
        if (contract && account) {
            const balance = await contract.balanceOf(account);
            const tokens = new Set();
            const vested = {};
            const claimed = {};
            const claimable = {};
            for (let i = 0; i < balance; i++) {
                const tokenId = await contract.tokenOfOwnerByIndex(account, i);
                const tokenStr = tokenId.toString();
                tokens.add(tokenStr);

                const totalVested = await contract.vestedAmount(tokenId);
                vested[tokenStr] = totalVested.toString();

                const totalClaimed = await contract.claimedPayout(tokenId);
                claimed[tokenStr] = totalClaimed.toString();
                const claimableAmount = await contract.claimablePayout(tokenId);
                claimable[tokenStr] = claimableAmount.toString();
            }

            setOwnedTokens([...tokens]);
            setVestedTokens(vested);
            setClaimedTokens(claimed);
            setClaimablePayouts(claimable);
            setSelectedTokenId([...tokens][0]);
        }
    };

    useEffect(() => {
        fetchTokens();
    }, [contract, account]);


 


    const handleStake = async () => {
        if (!stakingContract || !stakeAmount) {
            console.error("Staking contract not initialized or stake amount not specified.");
            setSnackbarMessage("Staking contract not initialized or stake amount not specified.");
            setSnackbarSeverity("error");
            setShowSnackbar(true);
            return;
        }
    
        try {
            const signer = provider.getSigner();
            const stakingContractWithSigner = stakingContract.connect(signer);
            const amountToStake = ethers.utils.parseUnits(stakeAmount, 18); // Convert stakeAmount to a BigNumber with 18 decimals
    
            // Ensure the user has enough AVAX for the transaction fee
            const userBalance = await provider.getBalance(account);
            if (userBalance.lt(ethers.utils.parseEther("0.1"))) { // Check if user's balance is less than required fee
                setSnackbarMessage("Insufficient AVAX for transaction fee.");
                setSnackbarSeverity("error");
                setShowSnackbar(true);
                return;
            }
    
            const tx = await stakingContractWithSigner.stake(amountToStake, {
                value: ethers.utils.parseEther("0.1") // Sending exactly 0.1 AVAX as fee
            });
            await tx.wait(); // Wait for the transaction to be mined
    
            // Fetch the updated staked amount
            await fetchStakedBalance();
    
            setSnackbarMessage("Staked successfully!");
            setSnackbarSeverity("success");
            setShowSnackbar(true);
        } catch (error) {
            console.error("Error during staking:", error);
            setSnackbarMessage("Staking failed.");
            setSnackbarSeverity("error");
            setShowSnackbar(true);
        }
    };
    
    
    const handleWithdraw = async () => {
        if (!stakingContract || !account || !withdrawAmount) {
            setSnackbarMessage("Please enter a valid amount to withdraw.");
            setSnackbarSeverity("error");
            setShowSnackbar(true);
            return;
        }
    
        try {
            const signer = provider.getSigner();
            const stakingContractWithSigner = stakingContract.connect(signer);
            const amountToWithdraw = ethers.utils.parseUnits(withdrawAmount, 18); // Convert withdrawAmount to BigNumber
    
            // Ensure the user has enough AVAX for the transaction fee
            const userBalance = await provider.getBalance(account);
            if (userBalance.lt(ethers.utils.parseEther("0.1"))) { // Check if user's balance is less than required fee
                setSnackbarMessage("Insufficient AVAX for transaction fee.");
                setSnackbarSeverity("error");
                setShowSnackbar(true);
                return;
            }
    
            const tx = await stakingContractWithSigner.withdraw(amountToWithdraw, {
                value: ethers.utils.parseEther("0.1") // Sending exactly 0.1 AVAX as fee
            });
            await tx.wait(); // Wait for the transaction to be mined
    
            setWithdrawAmount(''); // Reset withdrawAmount state
            await fetchStakedBalance(); // Fetch the updated staked balance
    
            setSnackbarMessage(`Withdrawn successfully!`);
            setSnackbarSeverity("success");
            setShowSnackbar(true);
        } catch (error) {
            console.error('Error withdrawing tokens:', error);
            setSnackbarMessage("Withdrawal failed.");
            setSnackbarSeverity("error");
            setShowSnackbar(true);
        }
    };
    
    
    


    const handleHarvest = async () => {
        if (!stakingContract || !account) {
            setSnackbarMessage("Staking contract not initialized.");
            setSnackbarSeverity("error");
            setShowSnackbar(true);
            return;
        }
    
        try {
            const signer = provider.getSigner();
            const stakingContractWithSigner = stakingContract.connect(signer);
    
            // Ensure the user has enough AVAX for the transaction fee
            const userBalance = await provider.getBalance(account);
            if (userBalance.lt(ethers.utils.parseEther("0.1"))) { // Check if user's balance is less than required fee
                setSnackbarMessage("Insufficient AVAX for transaction fee.");
                setSnackbarSeverity("error");
                setShowSnackbar(true);
                return;
            }
    
            const tx = await stakingContractWithSigner.harvest({
                value: ethers.utils.parseEther("0.1") // Sending exactly 0.1 AVAX as fee
            });
            await tx.wait(); // Wait for the transaction to be mined
    
            setSnackbarMessage("Rewards harvested successfully!");
            setSnackbarSeverity("success");
            setShowSnackbar(true);
    
            // Update rewards after harvesting
            updateRewards();
        } catch (error) {
            console.error('Error harvesting rewards:', error);
            setSnackbarMessage("Harvesting failed.");
            setSnackbarSeverity("error");
            setShowSnackbar(true);
        }
    };
    



    useEffect(() => {
        if (showSnackbar) {
            const timer = setTimeout(() => {
                setShowSnackbar(false);
            }, 3000); // Hide after 3 seconds
    
            return () => clearTimeout(timer);
        }
    }, [showSnackbar]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowSnackbar(false);
    };

    
const handleSpecialAction = async () => {
    if (!selectedTokenDetails) return;
    
    const tokenId = selectedTokenDetails.tokenId;
  
    console.log(`Special action triggered for token ID: ${tokenId}`);
  
    // Example: Log more detailed information about the token
    console.log(`Token Details: ${JSON.stringify(selectedTokenDetails.metadata)}`);
  
    // Example: Initiate a transaction, such as staking the token
    try {
      const tx = await stakingContract.stakeToken(tokenId);
      await tx.wait(); // Wait for the transaction to be mined
      console.log(`Token ID: ${tokenId} staked successfully`);
    } catch (error) {
      console.error(`Failed to stake token ID: ${tokenId}`, error);
    }
  
    // Close the modal after performing the special action
    setShowModal(false);
  };

  const handleTokenClick = (tokenId, imageUrl, metadata) => {
    // Ensure metadata is structured correctly and includes attributes
    const safeMetadata = metadata?.attributes ? metadata : { attributes: [] };
    setSelectedTokenDetails({ tokenId, imageUrl, metadata: safeMetadata });
    setShowModal(true);
  };
  

  const fetchTokenMetadata = async (tokenId) => {
    // Assume tokenContract is an instance of your smart contract
    const dataUri = await tokenContract.tokenURI(tokenId);
    const base64Json = dataUri.split(',')[1]; // Assuming the data URI follows the format "data:application/json;base64,..."
    const decodedJson = atob(base64Json);
    const metadata = JSON.parse(decodedJson);
    return metadata;
  };
  
  const displayTokenColor = async (tokenId) => {
    const metadata = await fetchTokenMetadata(tokenId);
    const color = metadata.attributes.find(attr => attr.trait_type === "Color")?.value || 'N/A';
    console.log(`Color of token ${tokenId}:`, color);
  };
  


    const handleMaxClick = () => {
        setStakeAmount(munBalance); // Set stakeAmount to munBalance when Max is clicked
      };

      const handleApprove = async () => {
        if (!tokenContract || !account) {
          console.error("Token contract not initialized or account not available.");
          return;
        }
      
        try {
          const signer = provider.getSigner();
          const tokenContractWithSigner = tokenContract.connect(signer);
      
          const tx = await tokenContractWithSigner.approve(LpTokenRecipientAddress, MAX_UINT256);
          await tx.wait();
      
          setHasApprovedMax(true); // Update the approval status
          console.log("Approval successful!");
        } catch (error) {
          console.error("Approval failed:", error);
        }
      };
      

      return (
        <div>
          {selectedTokenId && (
            <>
              <ContentContainer>
                <MediaImageContainer>
                  <ImageContainer>
                    <BalanceInfoContainer>
                      <p>Balance: {parseFloat(munBalance).toFixed(6)} SOUL</p>
                      <p>Staked: {stakedBalance} SOUL</p>
                      <p>Pending: {parseFloat(pendingRewards).toFixed(18)} SOUL</p>
                    </BalanceInfoContainer>
                    <GalleryContainer>
      <CenteredDiv>
        {Object.entries(tokenImages).map(([imageUrl, tokenData], index) => (
          <div key={index} onClick={() => handleTokenClick(tokenData.tokenId, imageUrl, tokenData.metadata)}>
            <TokenImage src={imageUrl} alt={`Token Image ${index}`} />
            <TokenCount>{`x${tokenCounts[imageUrl]}`}</TokenCount>
            {/* Optionally, display a special button for special tokens */}
            {specialTokens[tokenData.tokenId] && <SpecialButton>Special Info</SpecialButton>}
          </div>
        ))}


      


      </CenteredDiv>


    </GalleryContainer>


    <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Token Details</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {selectedTokenDetails && (
      <div>
        <img src={selectedTokenDetails.imageUrl} alt="Token" style={{ width: '100%' }} />
        <p>Token ID: {selectedTokenDetails.tokenId}</p>
        {/* Safely access color attribute using optional chaining */}
        <p>Color: {selectedTokenDetails.metadata.attributes.find(attr => attr.trait_type === "color")?.value || 'N/A'}</p>
        {/* Add the special action button here */}
        <SpecialButton onClick={() => handleSpecialAction(selectedTokenDetails.tokenId)}>
          Special Action
        </SpecialButton>
      </div>
    )}
  </Modal.Body>
</Modal>






                  </ImageContainer>
                </MediaImageContainer>
              </ContentContainer>
                {/* Display the spinner only if no images have been loaded yet */}
        {Object.keys(loadedImages).length === 0 && (
          <SpinnerDiv>
            <Spinner />
          </SpinnerDiv>
        )}
              {!hasApprovedMax && (
  <ApproveButton onClick={handleApprove}>Approve Max</ApproveButton>
)}{hasApprovedMax && (

                    <TabsContainer>
                      <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} aria-label="Staking options" className="mb-1">
                        <Tab label="Stake" value="stake" />
                        <Tab label="Withdraw" value="withdraw" />
                        <Tab label="Harvest" value="harvest" />
                      </Tabs>
                      <TabPanel value={activeTab} index="stake">
                        <TabContent>
                          <InputGroup>
                            <StyledInput
                              type="text"
                              value={stakeAmount}
                              onChange={(e) => setStakeAmount(e.target.value)}
                              placeholder="Amount to stake"
                            />
                            <MaxButton onClick={handleMaxClick}>Max</MaxButton>
                          </InputGroup>
                          <StakeButton onClick={handleStake} disabled={isStaking}>Stake</StakeButton>
                        </TabContent>
                      </TabPanel>
                      <TabPanel value={activeTab} index="withdraw">
                        <TabContent>
                            <InputGroup>
                            <StyledInput
                                type="text"
                                value={withdrawAmount}
                                onChange={(e) => setWithdrawAmount(e.target.value)}
                                placeholder="Amount to withdraw"
                            />
                            <MaxButton onClick={() => setWithdrawAmount(stakedBalance)}>Max</MaxButton>
                            </InputGroup>
                            <WithdrawButton onClick={handleWithdraw}>Withdraw</WithdrawButton>
                        </TabContent>
                        </TabPanel>
                      <TabPanel value={activeTab} index="harvest">
                        <TabContent>
                          <HarvestButton onClick={handleHarvest}>Harvest Rewards</HarvestButton>
                        </TabContent>
                      </TabPanel>
                    </TabsContainer>

)}
              <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                  {snackbarMessage}
                </Alert>
              </Snackbar>
            </>
          )}
          {!selectedTokenId && <NoTokenMessage>Please obtain a Moon to stake.</NoTokenMessage>}
          <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      );
};

export default StakingGallery;






const BalanceInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 0px; // Adjust as needed
`;




const NoTokenMessage = styled.div`
    color: #fff; // or any color you prefer
    text-align: center;
    padding: 20px;
    font-size: 16px; // or any size you prefer
`;

export const InfoButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 140px;
  min-width: 140px;
  height: 40px;
  //font-family: ${Fonts.MedievalSharp};
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: ${Transitions.all};
  margin-top: -20px;
  

  &:hover,
  &:focus {
    background-color: #FFBFA9;
    color: #000000;
     border: 2px solid #FFBFA9;
  border-radius: ${BorderRad.m};
  }`


export const StakeButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  width: 140px;
  min-width: 140px;
  height: 60px;
  //font-family: ${Fonts.MedievalSharp};
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 2.0s, color 1.0s, border-color 0.3s; /* Transition multiple properties */

  

  &:hover,
  &:focus {
    background-color: #91edd9ff;
    color: #000000;
     border: 2px solid #FFBFA9;
  border-radius: ${BorderRad.m};
  }`

  export const WithdrawButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 140px;
  min-width: 140px;
  height: 60px;
  //font-family: ${Fonts.MedievalSharp};
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 2.0s, color 1.0s, border-color 0.3s; /* Transition multiple properties */

  

  &:hover,
  &:focus {
    background-color: #b699ffff;
    color: #000000;
     border: 2px solid #FFBFA9;
  border-radius: ${BorderRad.m};
  }`


  export const HarvestButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 140px;
  min-width: 140px;
  height: 60px;
  //font-family: ${Fonts.MedievalSharp};
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  margin-top: 00px;
  transition: background-color 2.0s, color 1.0s, border-color 0.3s; /* Transition multiple properties */

  

  &:hover,
  &:focus {
    background-color: #FFBFA9;
    color: #000000;
     border: 2px solid #FFBFA9;
  border-radius: ${BorderRad.m};
  }`

  export const ApproveButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 140px;
  min-width: 360px;
  height: 60px;
  //font-family: ${Fonts.MedievalSharp};
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: background-color 2.0s, color 1.0s, border-color 0.3s; /* Transition multiple properties */
  margin-left: auto;
  margin-right: auto;
  display: block; /* This ensures the button respects margin auto for horizontal centering */


  margin-top: 20px; // Adjust as needed
  text-decoration: none; // Remove underline from link

  &:hover, &:focus {
    background-color: #91edd9ff;
    color: #000000;
    border: 2px solid #FFBFA9;
    border-radius: ${BorderRad.m};
  }
`;

export const ClaimButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 140px;
  min-width: 140px;
  height: 40px;
  //font-family: ${Fonts.MedievalSharp};
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: ${Transitions.all};
  margin-top: 70px;
 
  

  &:hover,
  &:focus {
    background-color: #FFBFA9;
    color: #000000;
     border: 2px solid #FFBFA9;
  border-radius: ${BorderRad.m};
  }`

export const ModalClaimButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 140px;
  min-width: 140px;
  height: 40px;
  //font-family: ${Fonts.MedievalSharp};
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: ${Transitions.all};
  margin-top: 20px;
  

  &:hover,
  &:focus {
    background-color: #FFBFA9;
    color: #000000;
     border: 2px solid #FFBFA9;
  border-radius: ${BorderRad.m};
  }`





  const ContentContainer = styled.div`
  display: flex;
  flex-direction: column; /* Changed from row to column to stack components vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  gap: 20px; /* Add some space between child components */
`;
const MediaImageContainer = styled.div`
  display: flex;
  flex-direction: column; /* Keep children stacked vertically */
  align-items: center; /* Center children horizontally */
  width: 100%; /* Take up full width */
`;






const ImageContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
`;


const Grid = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  max-width: 330px; // Added line to limit the maximum width of the grid container
`;




const MainImage = styled.img`
  width: 230px;
  height: 230px;
  margin-bottom: 5px;
  margin-top: -15px;

    @media (max-width: 768px) {
    width: 275px;
  height: 275px;
  }
`;




const CenteredText = styled.div`
    text-align: center;
`;





const ImagePlaceholder = styled.div`
  width: 100%; // take the full width of the container
  height: 220px; // fixed height for image area
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  color: white;
`;


const GalleryContainer = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Adjust minmax values as needed */
grid-gap: 20px;
width: 100%; /* Ensure the gallery takes up the full width of its container */
justify-items: center; /* Center items horizontally within their grid cell */

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)); /* Adjust the width of each column */
  
  justify-items: center; /* Center items horizontally within their grid cell */
  grid-gap: 20px;
  margin-bottom: 20px;
  margin-top: -20px;

  min-height: 220px; /* This will ensure the space remains even when images are not loaded */
  height: auto; /* This will ensure container grows with the content */
  align-items: start; /* Aligns items to the top, so the spinner is always at the top */
  @media (max-width: 768px) {
    max-height: 50px; /* Reduce maximum height for mobile */
  }
`;

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
    min-height: 220px; // Set a fixed minimum height to accommodate images

`;



const TokenImage = styled.img`
  display: block;
  margin: 10px;
  max-height: 90px; /* Set maximum height for desktop */
  
  @media (max-width: 768px) {
    max-height: 40px; /* Reduce maximum height for mobile */
    margin: 5px;

  }
`;

const TokenCount = styled.span`
  display: block;
  margin-top: 5px;
  color: white;
  text-align: center;
`;


const SpinnerDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
  margin-top: -110px;
  margin-bottom: -10px;


`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3); /* Light grey border */
  border-top: 4px solid #91edd9ff; /* White border for the top */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;



const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -50px; // Provide bottom margin to create space under the tabs
`;

const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px; // Space between tabs and input groups
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row; // Set the direction to row to align items horizontally
  align-items: center; // Center items vertically in the container
  gap: 10px; // Add some space between the input and the Max button
`;

const StyledInput = styled.input`
  padding: 10px;
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  color: ${Colors.White};
  background-color: #000000;

  &:focus {
    outline: none;
    border-color: #FFBFA9;
  }
`;

const MaxButton = styled.button`
  padding: 10px;
  background-color: #000000; /* Use your color theme for button background */
  color: ${Colors.White}; /* Use your color theme for text */
  border: 2px solid ${Colors.White}; /* Use your color theme for border */
  border-radius: ${BorderRad.m}; /* Use your border radius theme */
  cursor: pointer;
  margin-top: 0px;

  transition: background-color 0.3s;

  &:hover {
    color: #91edd9ff; /* Use your color theme for text */

  }
`;



const SpecialButton = styled.button`
  padding: 10px 20px;
  background-color: #4CAF50; // Example green color
  color: white;
  border: none;
  border-radius: ${BorderRad.m};
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;

const SpecialActionButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 140px;
  min-width: 140px;
  height: 40px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: ${Transitions.all};
  margin-top: 20px;

  &:hover,
  &:focus {
    background-color: #FFBFA9;
    color: #000000;
    border: 2px solid #FFBFA9;
    border-radius: ${BorderRad.m};
  }
`;
