import React from 'react';
import styled from 'styled-components';
import './Navbar.css';
import { useWallet } from './WalletContext'; // Make sure this path is correct
import { BorderRad, Colors, Fonts, Transitions } from '../src/styles/global/styles';
import { ethers } from 'ethers'; // Import ethers to format the balance

const Navbar = () => {
  const { account, connectWallet, disconnectWallet, nativeTokenBalance } = useWallet(); // Extract nativeTokenBalance

  const handleConnectWallet = () => {
    connectWallet();
  };

  const handleDisconnectWallet = () => {
    disconnectWallet();
  };

  const shortenAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  // Format the nativeTokenBalance for display
  const formattedBalance = nativeTokenBalance ? ethers.utils.formatEther(nativeTokenBalance) : null;

  return (
    <div className="navbar">
        <ul></ul>
        <div className="account-info">
            {account ? (
                <>
                    {formattedBalance && (
                        <div className="balance-display" >
                            <BalanceWrapper>{parseFloat(formattedBalance).toFixed(4)} AVAX</BalanceWrapper>
                        </div>
                    )}
                    <Account className="shortened-account"><a>{shortenAddress(account)}</a></Account>
                    <WalletButton className="wallet-button" onClick={handleDisconnectWallet}>
                        Disconnect
                    </WalletButton>
                </>
            ) : (
                <WalletButton className="wallet-button" onClick={handleConnectWallet}>
                    Connect Wallet
                </WalletButton>
            )}
        </div>
    </div>
  );
};

export default Navbar;

const WalletButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 180px;
  min-width: 60px;
  height: 40px;
  //font-family: ${Fonts.MedievalSharp};
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid #b699ffff;
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: ${Transitions.all};
 @media (max-width: 768px) {
      width: 140px;
      height: 60px;
      min-width: 60px;
  
   &:hover,
  &:focus {
    background-color: #d4af37;
    color: #000000;
    border: 2px solid #000000;
    border-radius: ${BorderRad.m};
  }
  }
`;

const Account = styled.div`
  margin-left: 20px;
  font-family: 'Montserrat';

  @media (max-width: 768px) {
    display: none; /* Hide the account info on mobile */
  }
`;

const BalanceWrapper = styled.span`
  @media (max-width: 768px) {
    display: none; /* Hide the balance on mobile */
  }
`;
