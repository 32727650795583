import React, { useState, useEffect } from 'react';
import { useWallet } from '../WalletContext';
import { ethers } from 'ethers';
import Moons from '../abis/Moons.json';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { MoonsContractAddress } from './ContractAddress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { BorderRad, Colors, Transitions } from '../styles/global/styles';
import { Fonts } from '../styles/global/styles';

const MoonMarket = () => {
  const { provider, account } = useWallet();
  const [contract, setContract] = useState(null);
  const [listedMoons, setListedMoons] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  useEffect(() => {
    const init = async () => {
      if (provider) {
        const newContract = new ethers.Contract(MoonsContractAddress, Moons.abi, provider);
        setContract(newContract);
      }
    };
    init();
  }, [provider]);


  useEffect(() => {
    const fetchListedMoons = async () => {
      if (!contract) return;
  
      const totalSupply = await contract.totalSupply();
      const listedMoonsTemp = [];
  
      // Adjust the starting index here if your tokens start from an index other than 1
      const startIndex = 1; // Assuming your tokens start from ID 2
  
      for (let i = startIndex; i <= totalSupply.toNumber() + startIndex - 1; i++) {
        const listing = await contract.listings(i);
  
        if (listing.isListed) {
          const tokenURI = await contract.tokenURI(i);
          // Adjust this line if your tokenURI contains a base64-encoded JSON
          const metadata = atob(tokenURI.substring(29)); // Assuming tokenURI is base64 encoded
          const { image } = JSON.parse(metadata);
  
          listedMoonsTemp.push({
            tokenId: i.toString(),
            image,
            price: ethers.utils.formatEther(listing.price)
          });
        }
      }
      setListedMoons(listedMoonsTemp);
    };
  
    fetchListedMoons();
  }, [contract]);
  




  const handleBuyMoon = async (tokenId, price) => {
    if (!contract || !account) return;

    try {
      const signer = provider.getSigner();
      const contractWithSigner = contract.connect(signer);
      const transaction = await contractWithSigner.buyNFT(tokenId, { value: ethers.utils.parseEther(price) });
      await transaction.wait();

      setSnackbarMessage('Moon purchased successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to purchase moon:', error);
      setSnackbarMessage('Failed to purchase moon. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  return (
    <>
      <GridContainer>
        {listedMoons.map(moon => (
          <MoonContainer key={moon.tokenId}>
            <Thumbnail src={moon.image} alt={`Moon ${moon.tokenId}`} />
            <div>{moon.price} AVAX</div>
            <BuyButton variant="primary" onClick={() => handleBuyMoon(moon.tokenId, moon.price)}>Buy</BuyButton>
          </MoonContainer>
        ))}
      </GridContainer>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} elevation={6} variant="filled">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default MoonMarket;

// Styled-components
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  padding: 20px;
`;

const MoonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Thumbnail = styled.img`
  width: 100%;
  max-width: 150px; // Adjust size as needed
  height: auto;
  border-radius: ${BorderRad.m};
  margin-bottom: 10px;
`;

export const BuyButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 100px;
  min-width: 100px;
  height: 40px;
  //font-family: ${Fonts.MedievalSharp};
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.Black};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: ${Transitions.all};
  margin-top: 10px;
 
  

  &:hover,
  &:focus {
    color: #91edd9ff;
  border-radius: ${BorderRad.m};
  }`
