import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import './css/Page2.css';

import { useWallet } from '../WalletContext';
import MoonMarket from '../components/MOONmarket';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;


const Market = () => {
    const { account } = useWallet();
    const [loading, setLoading] = useState(false);
    const [displayedContent, setDisplayedContent] = useState(null);
    const [isGalleryVisible, setIsGalleryVisible] = useState(true);
    const [isVestingVisible, setIsVestingVisible] = useState(false);
    

    useEffect(() => {
        if (account) {
            setLoading(true);
            const timer = setTimeout(() => {
                setLoading(false);
                setIsVestingVisible(true); // Set isVestingVisible to true after loading is done
                setDisplayedContent(
                    <>
                        <TitleBox className="title-container">
                            <h1>Moon Market</h1>
                        </TitleBox>

                        <StyledDivVesting className={isVestingVisible ? 'fade-in' : ''}>
                            <MoonMarket/>

                        </StyledDivVesting>
                    </>
                );
            }, 3000); // Simulated loading time
            return () => clearTimeout(timer);
        }
    }, [account]);

    return (
        <>
            {loading ? (
                <Loader>
                    Loading...
                    <Spinner /> {/* Spinner is placed below the text */}
                </Loader>
                
            ) : account ? (
                displayedContent
            ) : (
                <ConnectWalletMessage>Please Connect Wallet to obtain a Moon</ConnectWalletMessage>
            )}
        </>
    );
};




export default Market;
const StyledDivVesting = styled.div`
     width: 65%;
    height: 590px;
    /* Add 600px to the existing height */
    margin: 0px auto 0;
    padding: 20px;
    border: 1px solid #91edd9ff;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.9);
    background-color: rgba(0, 0, 0, 0.8);
    color: #c3c3c3;
    animation: ${fadeIn} 1s ease forwards;


  @media (max-width: 768px) {
         width: 95%;
 border: 0px solid #ffffff;
     background-color: transparent;
     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.0);

  }
`;

const TitleBox = styled.div`
  {
    color: white;
    margin-top: 120px;
  }
`;

const VestingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-left: 20px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
      margin-left: 0px;

  }
`;



const VestingInteractionDiv = styled.div`
  display: relative;
  align-items: flex-end;
  margin-right: 20px;
  margin-top: 40px;
  margin-Bottom: 70px;

  ${({ isGalleryVisible }) =>
        !isGalleryVisible &&
        `
          margin-top: 0px;

    align-items: center;
    justify-content: center;
  `}

  @media (max-width: 768px) {
      margin-right: 00px;

    min-width: 60px;
    align-items: center;
  }
`;



const ConnectWalletMessage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: calc(100vh - 260px); // adjust this value based on your header height
    font-size: 24px;
    font-weight: bold;
`;



const Spinner = styled.div`
margin-top: 60px;
  border: 4px solid rgba(255, 255, 255, 0.3); /* Light grey border */
  border-top: 4px solid #91edd9ff; /* White border for the top */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

const Loader = styled.div`
  display: flex;
  flex-direction: column; /* Align children vertically */
  justify-content: center;
  align-items: center;
  height: calc(50vh - 0px);
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.0);
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  z-index: 1000;
`;

