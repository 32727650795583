import React, { useState, useEffect } from 'react';
import { useWallet } from '../WalletContext';
import { ethers } from 'ethers';
import Moons from '../abis/Moons.json';
import styled, { keyframes } from 'styled-components';
import { MoonsContractAddress, MUNContractAddress } from './ContractAddress.js';
import VestingClaim from './MOONSVestingClaim';
import Modal from 'react-bootstrap/Modal';
import { BorderRad, Colors, Fonts, Transitions } from '../styles/global/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Button } from 'react-bootstrap';


const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const VestingGallery = () => {
    const { provider, account } = useWallet();
    const [contract, setContract] = useState(null);
    const [ownedTokens, setOwnedTokens] = useState([]);
    const [selectedTokenId, setSelectedTokenId] = useState(null);
    const [vestedTokens, setVestedTokens] = useState({});
    const [claimedTokens, setClaimedTokens] = useState({});
    const [claimablePayouts, setClaimablePayouts] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showClaimPopup, setShowClaimPopup] = useState(false);
    const [tokenImages, setTokenImages] = useState({});
    const [loadedImages, setLoadedImages] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [loading, setLoading] = useState(true); // Start with loading state as true
    const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // Default severity
    const [timeUntilClaimable, setTimeUntilClaimable] = useState({}); // New state to store time until claimable for each token
    const [lastClaimTime, setLastClaimTime] = useState(Date.now());
    const [refreshTrigger, setRefreshTrigger] = useState(0); // Initialize to 0
const [isClaiming, setIsClaiming] = useState(false);

const [totalClaimedAmount, setTotalClaimedAmount] = useState('0');
const [remainingTokens, setRemainingTokens] = useState({}); // Add this line to define remainingTokens state
const [isListed, setIsListed] = useState(false);
const [listPrice, setListPrice] = useState('');
const [showListModal, setShowListModal] = useState(false);
const [showListForSaleModal, setShowListForSaleModal] = useState(false);



    const safeFormatEther = (value) => {
      // Check if value is undefined or null and use "0" as a fallback
      const safeValue = value !== undefined ? value : "0";
      // Use ethers.js utility function with a safe value
      let formattedValue = ethers.utils.formatEther(safeValue);
      // Ensure the value is converted to a float and then formatted to 6 decimal places
      formattedValue = parseFloat(formattedValue).toFixed(6);
      return formattedValue;
    };
  

    const formattedVestedTokens = safeFormatEther(vestedTokens[selectedTokenId]);
    const formattedClaimedTokens = safeFormatEther(claimedTokens[selectedTokenId]);
    const formattedClaimablePayouts = safeFormatEther(claimablePayouts[selectedTokenId]);


    const handleImageLoad = (tokenId) => {
      if (tokenImages[tokenId]) { // Check if the image URL exists for this token
          setLoadedImages((prevLoadedImages) => ({
              ...prevLoadedImages,
              [tokenId]: true,
          }));
      }
  };


    useEffect(() => {
        if (provider) {
            const newContract = new ethers.Contract(MoonsContractAddress,
                Moons.abi,
                provider
            );
            setContract(newContract);
        }
    }, [provider]);

    const handleThumbnailClick = async (tokenId) => {
      setSelectedTokenId(tokenId);
      
      // Use setTimeout to simulate a delay in fetching the token data
      setTimeout(async () => {
          if (contract && account) {
              try {
                  const totalVested = await contract.vestedAmount(tokenId);
                  const totalClaimed = await contract.claimedPayout(tokenId);
                  const claimableAmount = await contract.claimablePayout(tokenId);
                  const timeUntilClaim = await getTimeUntilClaimable(tokenId);
      
                  setVestedTokens(prev => ({ ...prev, [tokenId]: totalVested.toString() }));
                  setClaimedTokens(prev => ({ ...prev, [tokenId]: totalClaimed.toString() }));
                  setClaimablePayouts(prev => ({ ...prev, [tokenId]: claimableAmount.toString() }));
                  setTimeUntilClaimable(prev => ({ ...prev, [tokenId]: timeUntilClaim }));
              } catch (error) {
                  console.error('Error fetching token data:', error);
              }
          }
      }, 150);
    };
  
    

    useEffect(() => {
      const fetchTokenImages = async () => {
        if (contract && account) {
          const images = {};
          for (const tokenId of ownedTokens) {
            const tokenURI = await contract.tokenURI(tokenId);
            const { image } = JSON.parse(atob(tokenURI.split("base64,")[1]));
            images[tokenId] = image;
          }
          setTokenImages(images);
        }
      };
    
      fetchTokenImages();
    }, [contract, account, ownedTokens, lastClaimTime, refreshTrigger]); // Add refreshTrigger as a dependency



    const fetchTokens = async () => {
      if (contract && account) {
          const balance = await contract.balanceOf(account);
          const tokens = new Set();
          const vested = {};
          const PayoutClaimed = {};
          const claimable = {};
  
          for (let i = 0; i < balance; i++) {
              const tokenId = await contract.tokenOfOwnerByIndex(account, i);
              const tokenStr = tokenId.toString();
              tokens.add(tokenStr);
  
              const totalVested = await contract.vestedAmount(tokenId);
              vested[tokenStr] = totalVested.toString();
  
              const vestingDetails = await contract.vestingInfo(tokenId);
              const totalClaimedAmount = vestingDetails.totalClaimed; // Accessing totalClaimed from vestingInfo
              PayoutClaimed[tokenStr] = totalClaimedAmount.toString();
  
              const claimableAmount = await contract.claimablePayout(tokenId);
              claimable[tokenStr] = claimableAmount.toString();
          }
  
          setOwnedTokens([...tokens]);
          setVestedTokens(vested);
          setClaimedTokens(PayoutClaimed);
          setClaimablePayouts(claimable);
          setSelectedTokenId([...tokens][0]);
      }
  };
  
  useEffect(() => {
      fetchTokens();
  }, [contract, account]);



    useEffect(() => {
      const updateTokensAndImages = async () => {
          if (contract && account) {
              const balance = await contract.balanceOf(account);
              const tokens = new Set();
              for (let i = 0; i < balance; i++) {
                  const tokenId = await contract.tokenOfOwnerByIndex(account, i);
                  tokens.add(tokenId.toString());
              }
              const tokenArray = [...tokens];
              const images = {};
              for (const tokenId of tokenArray) {
                  const tokenURI = await contract.tokenURI(tokenId);
                  const { image } = JSON.parse(atob(tokenURI.split("base64,")[1]));
                  images[tokenId] = image;
              }
              // Now update states once at the end
              setOwnedTokens(tokenArray);
              setTokenImages(images);
              // Make sure to handle other state updates related to tokens here as needed
          }
      };

      


      const onMint = async (minter, tokenId) => {
        if (contract && account && minter.toLowerCase() === account.toLowerCase()) {
            console.log(`Mint event for token ${tokenId.toString()} by account ${account}`);
            await updateTokensAndImages();
            // Set Snackbar message and severity for a successful minting operation
            const message = `You have successfully minted a Moon.`;
            setSnackbarMessage(message);
            setSnackbarSeverity('success'); // Ensure the Snackbar is green
            setSnackbarOpen(true);
        }
    };
    

  
      if (contract && account) {
          contract.on("MintSingle", onMint);
  
          return () => {
              contract.off("MintSingle", onMint);
          };
      }
  }, [contract, account]); // Make sure to include any dependencies that are required for this effect
  

  useEffect(() => {
    if (contract && account) {
      const onMint = async (minter, tokenId) => {
        if (minter.toLowerCase() === account.toLowerCase()) {
          const tokenStr = tokenId.toString();
          const tokenURI = await contract.tokenURI(tokenId);
          const { image } = JSON.parse(atob(tokenURI.split("base64,")[1]));
  
          setOwnedTokens((prevOwnedTokens) => [...prevOwnedTokens, tokenStr]);
          setTokenImages((prevTokenImages) => ({ ...prevTokenImages, [tokenStr]: image }));
  
          setSelectedTokenId(tokenStr); // Optionally set the newly minted token as selected
        }
      };
  
      contract.on("MintSingle", onMint);
  
      return () => {
        contract.off("MintSingle", onMint);
      };
    }
  }, [contract, account]);
    

  const updateTokenData = async (tokenId) => {
    if (contract && account) {
      try {
        const totalVested = await contract.vestedAmount(tokenId);
        const totalClaimed = await contract.claimedPayout(tokenId);
        const claimableAmount = await contract.claimablePayout(tokenId);
  
        // Update the states with the new values
        setVestedTokens(prev => ({ ...prev, [tokenId]: totalVested.toString() }));
        setClaimedTokens(prev => ({ ...prev, [tokenId]: totalClaimed.toString() }));
        setClaimablePayouts(prev => ({ ...prev, [tokenId]: claimableAmount.toString() }));
      } catch (error) {
        console.error('Error updating token data:', error);
      }
    }
  };
  
  
  const handleClaim = async () => {
    if (contract && account && selectedTokenId) {
      const signer = provider.getSigner();
      const contractWithSigner = contract.connect(signer);
  
      try {
        const tx = await contractWithSigner.claim(selectedTokenId);
        setSnackbarMessage('Claim submitted. Waiting for confirmation...');
        setSnackbarSeverity('info');
        setSnackbarOpen(true);
  
        // Wait for the transaction to be mined
        await tx.wait();
  
        // Update the snackbar state for successful claim feedback after confirmation
        setSnackbarMessage('Claim successful!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
  
        // Invalidate cached image for this token and trigger re-fetch after the transaction is confirmed
        setTokenImages(prevTokenImages => ({
          ...prevTokenImages,
          [selectedTokenId]: `${prevTokenImages[selectedTokenId]}?v=${Date.now()}` // Append timestamp to bypass cache
        }));
        setLastClaimTime(Date.now()); // This should trigger any useEffect hooks that depend on lastClaimTime
        await updateTokenData(selectedTokenId);

      } catch (error) {
        console.error('Error during claim:', error);
        let errorMessage = 'Error during claim. Please try again later.';
  
        // Attempt to deeply parse the error object for a nested error message
        try {
          if (error.data && typeof error.data === 'string') {
            const parsedError = JSON.parse(error.data);
            if (parsedError.message) errorMessage = parsedError.message;
          } else if (error.data && error.data.message) {
            errorMessage = error.data.message;
          } else if (error.error && typeof error.error.data === 'string') {
            const errorData = JSON.parse(error.error.data);
            if (errorData.message) errorMessage = errorData.message;
          } else if (error.message) {
            errorMessage = error.message;
          }
  
          // Specific check for "Token is listed for sale"
          if (errorMessage.includes("Token is listed for sale")) {
            errorMessage = "Cannot claim tokens from a moon that is listed for sale.";
          }
          if (errorMessage.includes("Claiming delay not met")) {
            errorMessage = "Vesting delay for this NFT has not yet ended. Cannot claim at this time.";
          }
        } catch (parseError) {
          console.error('Error parsing the error message:', parseError);
        }
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };
  
  
  // useEffect for fetching token data including the image
  useEffect(() => {
    // Fetch token data including the image, triggered by changes in lastClaimTime
  }, [contract, account, ownedTokens, lastClaimTime]); // Add lastClaimTime as a dependency
  






  const renderThumbnails = () => {
    return ownedTokens.map((tokenId, index) => (
      <Thumbnail
          key={`${tokenId}-${index}`}
          src={tokenImages[tokenId] || ''}
          alt={`Token #${tokenId}`}
          onLoad={() => handleImageLoad(tokenId)}
  
          onClick={() => handleThumbnailClick(tokenId)}
          selected={tokenId === selectedTokenId}
          style={{ display: loadedImages[tokenId] ? 'block' : 'none' }}    />
  ));
  
  };
  
  
  const blackPlaceholder = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wQAAgMBAAI2BU3GAAAAAElFTkSuQmCC"; // This is a base64-encoded black square
  
  const renderMainImage = () => {
    // Define a function to handle image loading errors
    const handleImageError = (e) => {
      e.target.src = blackPlaceholder; // Set the image source to the black square if there's an error
    };
  
    return (
      <MainImage
        src={tokenImages[selectedTokenId] || blackPlaceholder} // Use the token image or black square if the token image is not available
        alt="Token image"
        onLoad={() => handleImageLoad(selectedTokenId)}
        onError={handleImageError} // Set the onError handler here
      />
    );
  };
  
    
    // useEffect for fetching token data including the image
    useEffect(() => {
      // Fetch token data including the image, triggered by changes in lastClaimTime
    }, [contract, account, ownedTokens, lastClaimTime]); // Add lastClaimTime as a dependency
    
  

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false); // Set loading to false after 3 seconds
        }, 50); // Set the timer for 3 seconds

        return () => clearTimeout(timer); // Clean up the timer
    }, []);

    const handleAddMUNToWallet = async () => {
        try {
          // Check if ethereum and wallet_watchAsset is available
          if (window.ethereum && window.ethereum.request) {
            const wasAdded = await window.ethereum.request({
              method: 'wallet_watchAsset',
              params: {
                type: 'ERC20', // Initially only supports ERC20, but eventually more standards will be added
                options: {
                  address: MUNContractAddress, // The address of the token contract
                  symbol: 'SOUL', // A ticker symbol or shorthand, up to 5 characters
                  decimals: 18, // The number of token decimals
                  // You can also add an image to represent the token
                  image: '<URL_FOR_TOKEN_IMAGE>', // Optional token image URL
                },
              },
            });
    
            if (wasAdded) {
              console.log('SOUL token was added to wallet!');
            } else {
              console.log('SOUL token was not added to wallet.');
            }
          } else {
            console.log('Ethereum provider is not available.');
          }
        } catch (error) {
          console.error('Failed to add SOUL token to wallet', error);
        }
      };



      const getTimeUntilClaimable = async (tokenId) => {
        const claimingDelay = await contract.claimingDelay(); // Get the claiming delay from the contract
        const vestingInfo = await contract.getVestingInfoByTokenId(tokenId); // Get the vesting info for the token
    
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        const vestingStartTime = vestingInfo.start.toNumber(); // Convert BigNumber to number
    
        const claimAvailableTime = vestingStartTime + claimingDelay.toNumber(); // Calculate when claim becomes available
        const timeUntilClaimable = claimAvailableTime - currentTime; // Time until claimable in seconds
    
        if (timeUntilClaimable > 0) {
            // Token is not yet claimable, convert time to a more readable format
            const minutesUntilClaimable = Math.floor(timeUntilClaimable / 60);
            const secondsUntilClaimable = timeUntilClaimable % 60;
            return `${minutesUntilClaimable} minutes and ${secondsUntilClaimable} seconds`;
        } else {
            // Token is already claimable
            return "Now";
        }
    };

 


    useEffect(() => {
      if (!contract) return;
  
      const onPayoutClaimed = (tokenId, claimer, amount) => {
          // Format the amount from wei to a readable format, assuming 18 decimal places
          const formattedAmount = ethers.utils.formatUnits(amount, 18);
  
          console.log(`PayoutClaimed event detected for tokenId ${tokenId.toString()} with amount ${formattedAmount}`);
  
          // Update the Snackbar message to show the formatted amount
          setSnackbarMessage(`Claim of ${formattedAmount.toString()} tokens successful for token ID: ${tokenId.toString()}`);
          setSnackbarOpen(true);
          setSnackbarSeverity('success');
      };
  
      contract.on("PayoutClaimed", onPayoutClaimed);
  
      return () => {
          if (contract) {
              contract.off("PayoutClaimed", onPayoutClaimed);
          }
      };
  }, [contract, setSnackbarMessage, setSnackbarOpen, setSnackbarSeverity]);
  
    
  useEffect(() => {
    const fetchTotalClaimed = async () => {
        if (contract && account) {
            try {
                // Replace '2' with the variable that holds the actual token ID if necessary
                const totalClaimed = await contract.claimedPayout('2');
                setTotalClaimedAmount(ethers.utils.formatEther(totalClaimed));
            } catch (error) {
                console.error('Error fetching total claimed amount:', error);
            }
        }
    };

    fetchTotalClaimed();
}, [contract, account]);



const [vestingInfo, setVestingInfo] = useState(null);

useEffect(() => {
  const fetchVestingInfo = async () => {
    if (contract) {
      try {
        const info = await contract.getVestingInfoByTokenId(2); // Assuming you're looking for token ID 2
        setVestingInfo(info);
      } catch (error) {
        console.error("Failed to fetch vesting info:", error);
      }
    }
  };

  fetchVestingInfo();
}, [contract]);



const handleListForSale = async () => {
  if (!contract || !selectedTokenId) return;

  const priceInWei = ethers.utils.parseUnits(listPrice, 'ether');
  const signer = provider.getSigner();
  const contractWithSigner = contract.connect(signer);

  try {
    const tx = await contractWithSigner.listNFT(selectedTokenId, priceInWei);
    await tx.wait();
    setIsListed(true);
    setShowListModal(false);
    setSnackbarMessage(`Moon successfully listed for sale for ${listPrice} AVAX`);
    setSnackbarOpen(true);
  } catch (error) {
    console.error('Error listing NFT:', error);
  }
};

const handleUpdatePrice = async () => {
  if (!contract || !selectedTokenId || !listPrice) return;

  const priceInWei = ethers.utils.parseUnits(listPrice, 'ether');
  const signer = provider.getSigner();
  const contractWithSigner = contract.connect(signer);

  try {
    const tx = await contractWithSigner.updateListingPrice(selectedTokenId, priceInWei);
    await tx.wait();
    setShowListModal(false); // Close the modal after successful transaction
    setSnackbarMessage(`Listing price updated to ${listPrice} AVAX`);
    setSnackbarOpen(true);
    setSnackbarSeverity('success');
  } catch (error) {
    console.error('Error updating listing price:', error);
    setSnackbarMessage('Failed to update listing price. Please try again.');
    setSnackbarOpen(true);
    setSnackbarSeverity('error');
  }
  setShowListForSaleModal(false);
};

const handleDelist = async () => {
  if (!contract || !selectedTokenId) return;

  const signer = provider.getSigner();
  const contractWithSigner = contract.connect(signer);

  try {
    const tx = await contractWithSigner.delistNFT(selectedTokenId);
    await tx.wait();
    setIsListed(false); // Update the isListed state to false after successful delisting
    setShowListModal(false); // Close the modal
    setSnackbarMessage('Moon successfully delisted');
    setSnackbarOpen(true);
    setSnackbarSeverity('success');
  } catch (error) {
    console.error('Error delisting NFT:', error);
    setSnackbarMessage('Failed to delist. Please try again.');
    setSnackbarOpen(true);
    setSnackbarSeverity('error');
  }
  setShowListForSaleModal(false);
};



useEffect(() => {
  const fetchListingStatus = async () => {
    if (contract && selectedTokenId) {
      const listing = await contract.listings(selectedTokenId);
      setIsListed(listing.isListed);
      if (listing.isListed) {
        setListPrice(ethers.utils.formatEther(listing.price));
      }
    }
  };

  fetchListingStatus();
}, [contract, selectedTokenId]);


const handleOpenListModal = (tokenId) => {
  setSelectedTokenId(tokenId);
  setShowListForSaleModal(true);
};



useEffect(() => {
  if (!contract || !account) return;

  const onMintSingle = async (minter, tokenId, event) => {
    // Check if the mint event is related to the current user
    if (minter.toLowerCase() === account.toLowerCase()) {
      // Update the owned tokens list
      setOwnedTokens(prevOwnedTokens => [...prevOwnedTokens, tokenId.toString()]);

      // Update token data for the newly minted token
      await updateTokenData(tokenId.toString());
    }
  };

  // Subscribe to the MintSingle event
  contract.on("MintSingle", onMintSingle);

  return () => {
    // Remove the event listener when the component unmounts or dependencies change
    contract.off("MintSingle", onMintSingle);
  };
}, [contract, account, setOwnedTokens, setSnackbarMessage, setSnackbarOpen, setSnackbarSeverity]);


    return (
        <div>
           

            {selectedTokenId && (
                <>


                    <ContentContainer>


                        <TextContainer>

                            <MainWrapper>
                                <VestingInfo>
                                    <TokenIdText>
                                        Token ID: {selectedTokenId.slice(0, 20)}
                                    </TokenIdText>
                                    <LeftText>
                                      
  <div>Total vested Souls:</div>
  <div>Remaining:</div>
  <div>Claimed:</div>
  <div>Claimable:</div>
</LeftText>
<RightText>
  <div>{vestedTokens[selectedTokenId] ? ethers.utils.formatEther(vestedTokens[selectedTokenId]).toString() : '0'}</div>
  <div>{parseFloat(formattedVestedTokens - formattedClaimedTokens).toFixed(6)}</div>
  <div>{formattedClaimedTokens}</div>
  <div>{formattedClaimablePayouts}</div>
  
</RightText>
<div>
<div>Time until claimable:</div>
<div>{timeUntilClaimable[selectedTokenId] || 'Fetching...'}</div>
</div>


                                    <div
                                        style={{
                                            marginTop: '5px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <ClaimButton onClick={handleClaim}>Claim</ClaimButton>
                                        <ListForSaleButton onClick={() => setShowListForSaleModal(true)}>List</ListForSaleButton>

                                        <AddMUNButton onClick={handleAddMUNToWallet}>Add SOUL to Wallet</AddMUNButton>

                                    </div>

                                    
                                </VestingInfo>
                                
                            </MainWrapper>
                           
                        </TextContainer>
                     
                        <MediaImageContainer>
                        
                            <ImageContainer>
                              
                                <MainImageContainer>
                                    {loadedImages[selectedTokenId] ? (
                                        <>
                                          <MainImageContainer>
                            {renderMainImage()}
                          </MainImageContainer>


                                        </>
                                    ) : (
                                        <Loader>
                                        <Spinner />
                                    </Loader>                                    )}
                                </MainImageContainer>
                            </ImageContainer>
                          {/**   <div style={{ marginTop: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                <InfoButton onClick={() => setShowModal(true)}>Token Info</InfoButton>
                            </div>*/}
                            <VestingClaim />
                            <MobileButtons>
                            <MobileClaimable>Claimable:</MobileClaimable>
                            <TokenIdText>
                                        Token ID: {selectedTokenId.slice(0, 20)}
                                    </TokenIdText>
                      <div >{formattedClaimablePayouts}</div>
                      <div>Time until claimable:</div>
<div>{timeUntilClaimable[selectedTokenId] || 'Fetching...'}</div>
                      <ClaimButton onClick={handleClaim}>Claim</ClaimButton>
                      <ListForSaleButton onClick={() => setShowListForSaleModal(true)}>List</ListForSaleButton>
                     
                      </MobileButtons>
                            <GridContainer>
                                <Grid>
                                    {renderThumbnails()}
                                </Grid>
                            </GridContainer>

                        </MediaImageContainer>
                    </ContentContainer>

       

                    <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
    <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
        {snackbarMessage}
    </Alert>
</Snackbar>
        



<Modal
  show={showListForSaleModal}
  onHide={() => setShowListForSaleModal(false)}
  centered
  className="custom-modal"
  backdropClassName="custom-modal-backdrop"
>
  <Modal.Header closeButton style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: 'white' }}>
    <Modal.Title>{isListed ? 'Update Listing' : 'List Moon for Sale'}</Modal.Title>
  </Modal.Header>
  <Modal.Body style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    {isListed && listPrice && (
      <div style={{ marginBottom: '10px', color: '#61dafb' }}>Current Price: {listPrice} AVAX</div>
    )}
    <MainImageContainerModal>
      <img src={tokenImages[selectedTokenId]} alt={`Moon ${selectedTokenId}`} />
    </MainImageContainerModal>
    <StyledInput
      type="text"
      placeholder="Enter price in AVAX"
      value={listPrice}
      onChange={(e) => setListPrice(e.target.value)}
    />
    <ButtonContainer>
      {!isListed && (
        <SellButton onClick={handleListForSale}>List for Sale</SellButton>
      )}
      {isListed && (
        <>
          <UpdateButton onClick={handleUpdatePrice}>Update Price</UpdateButton>
          <DelistButton onClick={handleDelist}>Delist</DelistButton>
        </>
      )}
    </ButtonContainer>
  </Modal.Body>
</Modal>






                </>

                
            )}
        </div>
    );
};

export default VestingGallery;


const StyledModalBody = styled(Modal.Body)`
  height: 250px; // Set the height to 250px
  overflow-y: auto; // Add scroll if content exceeds the height
`;



const Spinner = styled.div`
margin-top: 60px;
  border: 4px solid rgba(255, 255, 255, 0.3); /* Light grey border */
  border-top: 4px solid #91edd9ff; /* White border for the top */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

const Loader = styled.div`
  display: flex;
  flex-direction: column; /* Align children vertically */
  justify-content: center;
  align-items: center;
  height: calc(50vh - 0px);
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.0);
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  z-index: 1000;
`;



export const InfoButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 140px;
  min-width: 140px;
  height: 40px;
  //font-family: ${Fonts.MedievalSharp};
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: ${Transitions.all};
  margin-top: -20px;
  

  &:hover,
  &:focus {
    background-color: #faf9cdff;
    color: #000000;
     border: 2px solid #91edd9ff;
  border-radius: ${BorderRad.m};
  }`

export const ClaimButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 140px;
  min-width: 140px;
  height: 40px;
  //font-family: ${Fonts.MedievalSharp};
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: ${Transitions.all};
  margin-top: 70px;

  @media (max-width: 768px) {
    grid-column-gap: 0px;

    margin-top: 30px;
  }
  

  &:hover,
  &:focus {
    background-color: #91edd9ff;
    color: #000000;
     border: 2px solid #faf9cdff;
  border-radius: ${BorderRad.m};
  }`

  const ListForSaleButton = styled(ClaimButton)`
  margin-top: 10px; // Adjust the margin-top as needed to add some space between the buttons

  @media (max-width: 768px) {
    grid-column-gap: 0px;

    margin-bottom: 30px;
  }
`;


export const ModalClaimButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 140px;
  min-width: 140px;
  height: 40px;
  //font-family: ${Fonts.MedievalSharp};
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: ${Transitions.all};
  margin-top: 20px;

  
  

  &:hover,
  &:focus {
    background-color: #91edd9ff;
    color: #000000;
     border: 2px solid #faf9cdff;
  border-radius: ${BorderRad.m};
  }`


const LeftText = styled.div`
  text-align: left;
  display: inline-block;
  width: 50%;
      white-space: nowrap; // Prevent text from wrapping
      

`;

const RightText = styled.div`
  text-align: right;
  display: inline-block;
  width: 50%;


`;


const MainWrapper = styled.div`
  display: flex;
  margin-top: 0px;


    @media (max-width: 768px) {
  margin-top: 0px;
  
  }
  
`;

const VestingInfo = styled.div`
margin-top: 60px;
 top: 0;
  right: 0;
  

`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5%; // Adjust the gap value as needed

  & > :last-child {
    margin-left: auto;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;

    & > :last-child {
      margin-left: 0;
    }
  }
`;

const MediaImageContainer = styled.div`
  display: flex; // Make this a flex container
  flex-direction: column; // Stack children vertically
  align-items: center; // Center children horizontally
  justify-content: center; // Center children vertically
  width: 400px; // Set the fixed width here
  margin-right: 40px;

  @media (max-width: 768px) {
    width: 100%; // Take the full width on mobile
    margin-right: 0;
  }
`;

const TextContainer = styled.div`
  width: 100%; // Adjust the width to accommodate the gap
  

  @media (max-width: 768px) {
    width: 200%;
    display:none;
    
    }
`;

const GridContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
`;

const ImageContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
`;

const Thumbnail = styled.img`
  width: 50px;
  height: 52px;
  border: ${props => (props.selected ? '2px solid gold' : 'none')};
  cursor: pointer;
  margin: 5px;
  filter: brightness(70%);
  transition: filter 0.3s ease;
  flex-basis: calc(16.6667% - 10px); // Added line to control width based on 6 items per row

  &:hover {
    filter: brightness(100%);
  }
`;

const Grid = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  max-width: 330px; // Added line to limit the maximum width of the grid container
`;


const MainImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000; // Or any other background color you prefer
  position: relative; // Make this positioned relative to place the buttons absolutely within it

  width: 380px;
  height: 380px;

  @media (max-width: 768px) {
    width: 275px; // Adjust the size for mobile
    height: 225px;
  }
`;


const MainImage = styled.img`
  width: 380px;
  height: 380px;
  margin-bottom: 40px;
    @media (max-width: 768px) {
    width: 275px;
  height: 275px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.0);
`;

const OverlayText = styled.div`
margin-top: 240px;
margin-left: 10px;

  color: white;
  font-size: 7px;
  font-weight: bold;
`;
const OverlayText2 = styled.div`
margin-left: 10px;

  color: white;
  font-size: 7px;
  font-weight: bold;
`;
const OverlayText3 = styled.div`
margin-left: 10px;

  color: white;
  font-size: 7px;
  font-weight: bold;
`;


const CenteredText = styled.div`
    text-align: center;
`;

const ClaimPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  color: black;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const TokenIdText = styled.div`
    text-align: center;
      margin-bottom: 20px;

`;

const ImagePlaceholder = styled.div`
  width: 300px;
  height: 175px;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: white;
  margin-bottom: 30px;
`;

const AddMUNButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 140px;
  min-width: 140px;
  height: 50px;
  //font-family: ${Fonts.MedievalSharp};
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: ${Transitions.all};
  margin-top: 10px;

  @media (max-width: 768px) {
    grid-column-gap: 0px;

    margin-top: 20px;
  }
  
  &:hover,
  &:focus {
    background-color: #b699ffff;
    color: #000000;
     border: 2px solid #fff;
  border-radius: ${BorderRad.m};
  }`

  const StyledInput = styled.input`
  display: block; // Ensures the input takes up the full width of its container
  width: 40%; // Adjust this value as needed
  padding: 10px;
  margin: 20px auto; // Centers the input horizontally and adds margin on the top and bottom
  border-radius: 15px; // Provides the slightly rounded edges
  border: 1px solid #ccc; // Standard border, adjust color as needed
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #61dafb; // Changes the border color to blue when the input is focused
  }
`;


const MainImageContainerModal = styled.div`
  display: flex;
  justify-content: center; // Centers the child elements horizontally
  align-items: center; // Centers the child elements vertically

  img {
    max-width: 100%; // Ensures the image does not exceed the container width

    width: 350px;
    height: 350px; // Maintains the aspect ratio of the image
    margin-bottom: 20px; // Adds some space below the image
  }

  @media (max-width: 768px) {
    width: 275px;
    height: 275px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center; // Align buttons in the center horizontally
  gap: 10px; // Space between the buttons
  margin-top: 20px; // Margin on top to separate from the input field
 
`;


export const DelistButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 140px;
  min-width: 140px;
  height: 40px;
  //font-family: ${Fonts.MedievalSharp};
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: ${Transitions.all};
  margin-top: 0px;
  margin-bottom: 20px;

  

  &:hover,
  &:focus {
    background-color: #91edd9ff;
    color: #000000;
     border: 2px solid #faf9cdff;
  border-radius: ${BorderRad.m};
  }`


  export const UpdateButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 140px;
  min-width: 140px;
  height: 40px;
  //font-family: ${Fonts.MedievalSharp};
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: ${Transitions.all};
  margin-top: 0px;
  margin-bottom: 20px;


 
  

  &:hover,
  &:focus {
    background-color: #91edd9ff;
    color: #000000;
     border: 2px solid #faf9cdff;
  border-radius: ${BorderRad.m};
  }`

  export const SellButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 140px;
  min-width: 140px;
  height: 40px;
  //font-family: ${Fonts.MedievalSharp};
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: ${Transitions.all};
  margin-top: 0px;
  margin-bottom: 20px;

  

  &:hover,
  &:focus {
    background-color: #91edd9ff;
    color: #000000;
     border: 2px solid #faf9cdff;
  border-radius: ${BorderRad.m};
  }`


  export const MobileClaimButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 140px;
  min-width: 140px;
  height: 40px;
  //font-family: ${Fonts.MedievalSharp};
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: ${Transitions.all};
  margin-top: 70px;
 
  

  &:hover,
  &:focus {
    background-color: #91edd9ff;
    color: #000000;
     border: 2px solid #faf9cdff;
  border-radius: ${BorderRad.m};
  }`


  const MobileButtons = styled.div`
  display: none; // Hide by default

  @media (max-width: 768px) {
    display: flex; // Display as a flex container on mobile
    flex-direction: column; // Stack children (buttons) vertically
    justify-content: center; // Center the buttons vertically
    align-items: center; // Center the buttons horizontally
    position: absolute; // Position the buttons absolutely to overlay on the image
    transform: translateY(100%); // Move the buttons above the image
  }
`;


const MobileClaimable = styled.div`
display: none; // Hide by default

@media (max-width: 768px) {
  display:flex;
margin-top: 20px;}
`;