import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';
import styled from 'styled-components';
import menuIcon from './assets/menu.svg';

const LINKS = [
    
    { path: '/', label: 'Home', disabled: false },
    { path: '/', label: 'ARC Dash', disabled: true },
    { path: '/page1', label: 'Chef Dash', disabled: true },
    { path: '/page2', label: 'Portal Certs', disabled: true },
    { path: '/page3', label: 'LumberJack', disabled: true },
    { path: '/page4', label: 'GenerativeSVG', disabled: true },
    { path: '/AvaxMoons', label: 'Avax Moons', disabled: false },
    { path: '/Classified', label: 'Classified', disabled: true },
    { path: '/SOULforSOUL', label: 'SOUL for SOUL', disabled: false },
    { path: '/SOULforLP', label: 'SOUL for LP', disabled: false },
    { path: '/SoulGallery', label: 'Gallery Of Souls', disabled: true },
    { path: '/Swap', label: 'Swap', disabled: true },
    { path: '/MoonMarket', label: 'Moon Market', disabled: false },

    { path: '/LAB', label: 'Arcanum LAB', disabled: false },


];

const Sidebar = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const sidebarRef = useRef();

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target) && isSidebarOpen) {
            setIsSidebarOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSidebarOpen]);

    return (
        <>
            {isSidebarOpen ? null : (
                <MenuButton className="sidebar-toggle" onClick={toggleSidebar}>
                    <img src={menuIcon} alt="Menu" />
                </MenuButton>
            )}
            <div ref={sidebarRef} className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
                <ul>
                    {LINKS.map((link) => (
                        link.disabled ? null : ( // Only render the link if it's not disabled
                            <li key={link.path}>
                                <Link to={link.path} onClick={toggleSidebar}>
                                    {link.label}
                                </Link>
                            </li>
                        )
                    ))}
                </ul>
            </div>
        </>
    );
};

export default Sidebar;

const MenuButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin: 0;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
