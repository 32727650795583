import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Outlet } from 'react-router-dom';

import AvaxMoons from './pages/MOONS';
import MUNforMUN from './pages/MUNforMUN';
import MUNforLP from './pages/MUNforLP';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Home from './pages/HomePage';
import './App.css';
import PageWrapper from './components/PageWrapper.js';
import LandingPage from './pages/LandingPage';
import { Web3ReactProvider } from '@web3-react/core';
import { ethers } from 'ethers';
import { WalletProvider } from './WalletContext'; // Adjust the path as needed
import PortalCerts from './pages/Page2';
import SoulGallery from './pages/SoulGallery.js';
import Swap from './pages/Swap.js';
import LAB from './pages/LAB.js';
import Market from './pages/Market.js';

function getLibrary(provider) {
  return new ethers.providers.Web3Provider(provider);
}
const Layout = () => {
  const location = useLocation();
  const isLandingPage = location.pathname === '/';

  return (
    <>
      {!isLandingPage && <Navbar />}
      {!isLandingPage && <Sidebar />}
      <Outlet /> {/* This is where the nested routes will get rendered */}
    </>
  );
};

function App() {
  return (
    <WalletProvider>

    <Router>
      <PageWrapper>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          {/* Wrap the Layout component around the routes that should have Navbar and Sidebar */}
          <Route element={<Layout />}>
            {/* Define nested routes here */}
            <Route path="/AvaxMoons" element={<AvaxMoons />} />
            <Route path="/SOULforSOUL" element={<MUNforMUN />} />
            <Route path="/SOULforLP" element={<MUNforLP />} />
            <Route path="/page2" element={<PortalCerts />} />
            <Route path="/SoulGallery" element={<SoulGallery />} />
            <Route path="/Swap" element={<Swap />} />
            <Route path="/MoonMarket" element={<Market/>} />

            <Route path="/LAB" element={<LAB />} />




            {/* Add more nested routes as needed */}
          </Route>
        </Routes>
      </PageWrapper>
    </Router>
    </WalletProvider>
    
  );
}

export default App;
