import React, { createContext, useContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';

const WalletContext = createContext();

const providerOptions = {
    walletconnect: {
        package: WalletConnectProvider, // required
        options: {
            infuraId: 'YOUR_INFURA_ID', // required for WalletConnect unless you're using custom RPC URLs below
            rpc: {
                43114: 'https://avalanche.drpc.org', // Avalanche Mainnet C-Chain
                43113: 'https://api.avax-test.network/ext/bc/C/rpc',
                // Add other networks as needed
            },
            qrcodeModalOptions: {
                mobileLinks: [
                    "metamask", // Add other mobile wallet apps you want to support
                    "trust",
                ],
            },
        },
    },
    // Add other providers here as needed
};

const web3Modal = new Web3Modal({
    network: "mainnet", // Default network
    cacheProvider: true, // Enable cache to remember the provider
    providerOptions, // required
});

export const WalletProvider = ({ children }) => {
    const [provider, setProvider] = useState(null);
    const [account, setAccount] = useState(null);
    const [nativeTokenBalance, setNativeTokenBalance] = useState(0);
    const [chainID, setChainID] = useState(null);

    useEffect(() => {
        if (web3Modal.cachedProvider) {
            connectWallet();
        }
    }, []);

    const connectWallet = async () => {
        try {
            const modalProvider = await web3Modal.connect();
            const web3Provider = new ethers.providers.Web3Provider(modalProvider);
            setProvider(web3Provider);

            const signer = web3Provider.getSigner();
            const _account = await signer.getAddress();
            setAccount(_account);

            const network = await web3Provider.getNetwork();
            setChainID(network.chainId);

            const balance = await web3Provider.getBalance(_account);
            setNativeTokenBalance(balance);

            // Listen for account changes
            modalProvider.on("accountsChanged", (accounts) => {
                setAccount(accounts[0]);
                // Optionally fetch new balance, etc.
            });

            // Listen for chain changes
            modalProvider.on("chainChanged", (chainId) => {
                setChainID(chainId);
                // Optionally handle the chain change
            });

            // Listen for provider disconnection
            modalProvider.on("disconnect", (code, reason) => {
                disconnectWallet();
            });
        } catch (error) {
            console.error("Connection failed: ", error);
        }
    };

    const disconnectWallet = async () => {
        web3Modal.clearCachedProvider();
        if (provider?.disconnect && typeof provider.disconnect === 'function') {
            await provider.disconnect();
        }
        setProvider(null);
        setAccount(null);
        setNativeTokenBalance(0);
    };

    return (
        <WalletContext.Provider value={{
            provider,
            account,
            nativeTokenBalance,
            chainID,
            connectWallet,
            disconnectWallet,
        }}>
            {children}
        </WalletContext.Provider>
    );
};

export const useWallet = () => useContext(WalletContext);
