export const Colors = {
    Black: {
        900: '#000000',
        200: '#000000',
        100: '#1E2023',
    },
    Gray: {
        600: '#757575',
        300: '#E0E0E0',
    },
    White: '#ffffff',
    Yellow: {
        500: '#F2C94C',
        200: '#fff9e6',
        100: '#FFF4D4',
    },
    Green: {
        500: '#122e00',
        200: '#205400',
        100: '#3da100',
    },
    Purple: {
        500: '#500083',
        200: '#338300',
        100: '#55d800',
    },


    Red: { 400: '#830e00' },
    Teal: {
        400: '#1A9686',
        200: '#22C984'
    },
    Magenta: { 400: '#960B92' },
    Gold: { 400: '#FCD377' },
    Rust: { 400: '#96490B' },



}





export const Fonts = {
    Helvetica: "HelveticaNeue",
    MedievalSharp: "MedievalSharp",
    abeezee: "abeezee"

}

export const Shadows = {
    main: '10px 12px 20px 4px rgba(0,1,1,1)',
    notification: '0px 4px 14px rgba(136, 169, 200, 0.3)',
}

export const Gradients = {
    bodyBackground: `linear-gradient(180deg, ${Colors.White}, ${Colors.Green[200]} )`,
    border: `linear-gradient(45deg, ${Colors.White}, ${Colors.Green[200]} )`
}



export const Transitions = {
    duration: '0.25s',
    all: 'all 0.25s ease',
}

export const BorderRad = {
    s: '14px',
    m: '12px',
    round: '50%',
    full: '1000px',

}
