import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom'; 
import './css/LandingPage.css'; // Make sure to create this CSS file
import styled from 'styled-components';
import { BorderRad, Colors, Fonts, Transitions } from '../styles/global/styles';
import './css/Page2.css';
import { FaDiscord, FaTelegramPlane, FaShareAlt, FaTwitter } from 'react-icons/fa'; // Import icons from react-icons
import { MUNContractAddress } from '../components/ContractAddress';


const LandingPage = () => {


    const [showTitle, setShowTitle] = useState(false);
    const [showTechnical, setShowTechnical] = useState(false); // State for technical description visibility

    const toggleTechnicalDescription = () => {
        console.log("Toggling technical description"); // Debugging line
        setShowTechnical(!showTechnical); // Toggle the visibility
    };

    
    useEffect(() => {
      setShowTitle(true); // This triggers the fade-in effect on component mount
    }, []);


    const sectionsRef = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                    } else {
                        entry.target.classList.remove('visible');
                    }
                });
            },
            { threshold: 0.5 }
        );

        sectionsRef.current.forEach((section) => observer.observe(section));

        return () => sectionsRef.current.forEach((section) => observer.unobserve(section));
    }, []);

    const addToRefs = (el) => {
        if (el && !sectionsRef.current.includes(el)) {
            sectionsRef.current.push(el);
        }
    };

    return (
        <LandingWrapper>
        <div className="landing-page">
        <div className="hero-section">

        <div className="top-section">
                    <SocialLinks>
                        <SocialButton href="https://twitter.com/AvaxMoons" target="_blank"><FaTwitter /> </SocialButton>
                        <SocialButton href="https://discord.gg/bpa5bNdCUU" target="_blank"><FaDiscord /> </SocialButton>
                        <SocialButton href="https://t.me/avaxMoons" target="_blank"><FaTelegramPlane /> </SocialButton>
                        <SocialButton href="https://snowtrace.io/address/0xDB361CFBCFF0225c07fa96e7f5127D50Cf2222A4/contract/43114/code" target="_blank"><FaShareAlt /> Snowtrace</SocialButton>
                    </SocialLinks>
                </div>
          <div className="moon-animation">
            <img 
                 src="/Moons.gif" 
                 alt="Moons" 
                 style={{
                 marginLeft: '15%', 
                 borderRadius: '15px',
                 width: '70%', // Increase width as needed
                 height: 'auto' // Maintain aspect ratio
                  }}
                 />
                </div>

        <div className={`title-button-container`}>
            <TitleBox className={`${showTitle ? 'fade-in' : ''}`}>
              <h1>AVAX Moons</h1>
            </TitleBox>
            <EnterAppButton to="/AvaxMoons">Enter App</EnterAppButton>
          </div>

          <BottomSection>
                 <ContractAddress>{MUNContractAddress}</ContractAddress>

            </BottomSection>
        </div>

        

            <div className="roadmap-container">


            <h2>The Genesis of Moons</h2>
                {/* The Genesis of Moons */}
                <section className="roadmap-entry" ref={addToRefs}>
                    <div className="roadmap-graphic" style={{ backgroundImage: "url('./Enchanted Atelier.webp')" }}></div>
                    <p>In the twilight of our realm, the Enchanted Atelier beckons artisans to craft the first Moons, embedding them with SOUL. This marks the dawn of prosperity, enriching our world with each Moon born.</p>
                </section>


                <h2>The Convergence of Magic and Economy</h2>
                {/* The Convergence of Magic and Economy */}
                <section className="roadmap-entry" ref={addToRefs}>
                    <div className="roadmap-graphic" style={{ backgroundImage: "url('./The Convergence of Magic and Economy .webp')" }}></div>
                    <p>Days after the first Moon's creation, the SOUL Liquidity Well is blessed, intertwining our realm's magic and commerce. Guardians of Moons embark on quests, with the potent SOUL lying in wait to be unearthed.</p>
                </section>


                <h2>The Ritual of Prosperity</h2>
                {/* The Ritual of Revenue */}
                <section className="roadmap-entry" ref={addToRefs}>
                    <div className="roadmap-graphic" style={{ backgroundImage: "url('./The Ritual of Revenue.webp')" }}></div>
                    <p>With the Moons' emergence, gates open for SOUL staking, inviting only Moon holders to partake in this sacred rite, weaving their fates with SOUL's essence. This pivotal phase not only enhances the utility and value of SOUL within the realm but also sets the stage for the next chapter of our saga.</p>
                </section>

                <h2>The Era of Shared Guardianship</h2>
                {/* The Paradox of the Unclaimed MUN */}
                <section className="roadmap-entry" ref={addToRefs}>
                    <div className="roadmap-graphic" style={{ backgroundImage: "url('./TheEraOf.webp')" }}></div>
                    <p>Following the initiation of SOUL staking, the realm embarks on a new chapter: SOUL for LP staking. This evolution aims to weave the fabric of our domain together with the collective will of its denizens, ushering in an era of shared guardianship. Through this, the protocol's essence is not just preserved but nurtured by the community, ensuring a robust and united future for all who dwell within.</p>
                </section>


                <h2>The Paradox of Slumbering SOUL</h2>
                {/* The Paradox of the Unclaimed MUN */}
                <section className="roadmap-entry" ref={addToRefs}>
                    <div className="roadmap-graphic" style={{ backgroundImage: "url('./The Paradox of the Unclaimed MUN.webp')" }}></div>
                    <p>
              As our odyssey unfolds, some Moons harbor untapped SOUL, enshrouded in the arcane. These Moons, brimming with dormant power, ascend as coveted relics, their allure echoing through the annals of legend.</p>
                </section>

                <h2>The Tapestry of Project Soul</h2>
                {/* The Tapestry of Project Mun */}
                <section className="roadmap-entry" ref={addToRefs}>
                    <div className="roadmap-graphic" style={{ backgroundImage: "url('/The Tapestry of Project Mun.webp')" }}></div>
                    <p>Thus, the saga of Project Soul weaves through creation, trade, and the mystique of liquidity, crafting a tale of magic, prosperity, and unity. Each participant, from mages to guardians, spins a thread in this ever-evolving tapestry.</p>
                </section>

             
                <ButtonContainer>

                <TechButton onClick={toggleTechnicalDescription}>
                    Break the Mythos (Reveal the Technical Description)
                </TechButton>
                </ButtonContainer>

                {showTechnical && ( 
                      <section className="roadmap-tech" ref={addToRefs}>
                      <div className="roadmap-techgraphic" style={{ backgroundImage: "url('/40404.gif')" }}></div>
                      <h2>The Technical Essence of Moons:</h2>
                      <p>Moons are innovative on-chain SVG NFTs integrated with a unique functionality that allows for the vesting of SOUL tokens. The vesting process commences 7 days subsequent to the minting of each Moon NFT, introducing a novel dynamic to the NFT ecosystem and effectively acting as a presale for SOUL. SOUL tokens, governed by the ERC-404 standard, embody a dual nature, functioning both as fungible and non-fungible tokens, thus extending versatility and utility within the ecosystem.</p>
                      <p>The introduction of SOUL for SOUL staking is set to follow shortly after establishing liquidity provisions for the SOUL token. This progression marks a significant phase in the utilization and value appreciation of SOUL tokens within the ecosystem.</p>
                      <p>It's important to note that these contracts, despite being rigorously tested, embody a blend of unique and experimental features. Users are advised to interact with them cautiously, keeping in mind the evolving nature of the protocol. The development team is committed to a rapid deployment schedule, aiming to introduce new features that leverage the rarity attributes of SOUL and enhance user engagement and value.</p>
                      <p>*FULL Moons are important*</p>
                  </section>
                )}



            </div>

            <Footer>
      <FooterContent>
        <FooterLinks>
          <SocialButton href="https://twitter.com/AvaxMoons" target="_blank"><FaTwitter /> </SocialButton>
          <SocialButton href="https://discord.gg/bpa5bNdCUU" target="_blank"><FaDiscord /> </SocialButton>
          <SocialButton href="https://t.me/avaxMoons" target="_blank"><FaTelegramPlane /> </SocialButton>
          <SocialButton href="https://snowtrace.io/address/0xDB361CFBCFF0225c07fa96e7f5127D50Cf2222A4/contract/43114/code" target="_blank"><FaShareAlt /> Snowtrace</SocialButton>
        </FooterLinks>
        <ContractAddress>{MUNContractAddress}</ContractAddress>
        <FooterButton to="/AvaxMoons">Enter App</FooterButton>
      </FooterContent>
    </Footer>
        </div>
        </LandingWrapper>
    );
};

export default LandingPage;


const LandingWrapper = styled.div`
margin-top: -100px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -20px; // Adjust as needed
  margin-bottom: 60px;
`;

export const EnterAppButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px; // Increased width
  height: 80px; // Increased height
  font-size: 1.8rem; // Increased font size
  line-height: 1.5;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: background-color 2.0s, color 1.0s, border-color 0.3s; /* Transition multiple properties */


  margin-top: 20px; // Adjust as needed
  text-decoration: none; // Remove underline from link

  &:hover, &:focus {
    background-color: #EADAB6;
    color: #000000;
    border: 2px solid #FFBFA9;
    border-radius: ${BorderRad.m};
  }
`;


export const TechButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%; // Increased width
  height: 80px; // Increased height
  font-size: 0.8rem; // Increased font size
  line-height: 1.5;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: background-color 2.0s, color 1.0s, border-color 0.3s; /* Transition multiple properties */


  margin-top: 20px; // Adjust as needed
  text-decoration: none; // Remove underline from link

  &:hover, &:focus {
    background-color: #91edd9ff;
    color: #000000;
    border: 2px solid #FFBFA9;
    border-radius: ${BorderRad.m};
  }
`;



const TitleBox = styled.div`
  color: white;
  text-align: center;

  h1 {
    font-size: 4rem; /* Increase the font size as needed */
    margin: 30; /* Optional: Adjust the margin to control spacing */
  }
`;


const SocialButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: #333;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 20px; // Adjust this value as needed for spacing from the top
`;


const BottomSection = styled.div`
    position: absolute;
    bottom: 20px; /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
`;

const ContractAddress = styled.p`
margin-top: 20px;
color:  #555;
font-size: 0.8rem; // Increased font size

`;



export const BuyButton = styled(Link)`

display: inline-flex;
align-items: center;
justify-content: center;
margin: 0 10px;
padding: 10px;
border-radius: 5px;
background-color: #000;
color: #fff;
text-decoration: none;
transition: background-color 0.9s;

&:hover {
  background-color: #333;
}
`;

const Footer = styled.footer`
  background-color: #333;
  padding: 20px 0;
  color: #fff;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: px;
`;

const FooterButton = styled(EnterAppButton)`
  margin-top: 20px;
`;