///////////////////////////////////////////////////////////////////////////////////////////////////////
///      ***                                   ***                                          ***     ///
///                                                                                                 ///
///   The Below Contracts Are MAINNET And Need to Be Commented Out BEFORE Launch And BEFORE Testing   ///
///                                                                                                 ///
///      ***                                   ***                                          ***     ///
///////////////////////////////////////////////////////////////////////////////////////////////////////





//export const contractAddress = '';  ///this is a generic address thats being used in many test files. 

export const MUNContractAddress = '0xdc9746c5b0827BebB596595dD896A9C6d911f38b';

export const MoonsContractAddress = '0xDB361CFBCFF0225c07fa96e7f5127D50Cf2222A4';

//export const LpTokenRecipientAddress = '0x91142Ca5be61DD6387674f51D38f27864c4ea668';

export const StakingRewardRecipientAddress = '0x283205CDE63d2fb03134084588b3ebC3eE47f56B';
export const MUNStakingContractAddress = '0x283205CDE63d2fb03134084588b3ebC3eE47f56B';





export const LPAddress = '0x36527aD60208aA32DC643C43aAa1264F5C5F9899';

export const TraderJoeRouterAddress = '0x60aE616a2155Ee3d9A68541Ba4544862310933d4';
export const WAVAX = '0xd00ae08403B9bbb9124bB305C09058E32C39A48c';



///////////////////////////////////////////////////////////////////////////////////////////////////////
///      ***                                   ***                                          ***     ///
///                                                                                                 ///
///   The Below Contracts Are TESTNET And Need to Be Commented Out AFTER Launch And AFTER Testing   ///
///                                                                                                 ///
///      ***                                   ***                                          ***     ///
///////////////////////////////////////////////////////////////////////////////////////////////////////




export const contractAddress = '';  ///this is a generic address thats being used in many test files. 

//export const MUNContractAddress = '0xeb4942279211Fd785188934C60Cdb65F5366d9D1';

//export const MoonsContractAddress = '0x8c5D667DaF89DDE146B03B86AC2181EeeE2c7C70';


////////       STAKING        ///////
//export const StakingRewardRecipientAddress = '0x9A921913D4a1cA5ce12d7542127a4dc423875872';
//export const MUNStakingContractAddress = '0x9A921913D4a1cA5ce12d7542127a4dc423875872';


////////      LP STAKING        ///////
export const MUNStakingLPContractAddress = '0x8f581aC8CF083b723bA5de28Df1Fd302444d75c5';
export const LpTokenRecipientAddress = '0x8f581aC8CF083b723bA5de28Df1Fd302444d75c5';
//export const LPAddress = '0x36527aD60208aA32DC643C43aAa1264F5C5F9899';







export const AvaPepeContractAddress = '0x41091c6107D52bbc063991457e37f12aAE553002';

