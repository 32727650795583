import React, { useState, useEffect } from 'react';
import { useWallet } from '../WalletContext';
import { ethers } from 'ethers';
import ProjectLumberjackJSON from '../abis/ProjectLumberjack.json';
import styled from 'styled-components';
import { MoonsContractAddress } from './ContractAddress.js';
import { BorderRad, Colors, Fonts, Transitions } from '../styles/global/styles';
import Tooltip from '@mui/material/Tooltip'; // Import Tooltip component

const CenteredText = styled.div`
    text-align: center;
`;

const VestingClaim = () => {
    const { provider, account } = useWallet();
    const [contract, setContract] = useState(null);
    const [claimableTokens, setClaimableTokens] = useState(null);

    useEffect(() => {
        if (provider) {
            const newContract = new ethers.Contract(MoonsContractAddress, ProjectLumberjackJSON.abi, provider);
            setContract(newContract);
        }
    }, [provider]);

    const fetchClaimableTokens = async () => {
        if (contract && account) {
            const balance = await contract.balanceOf(account);
            let totalClaimable = ethers.BigNumber.from(0);

            for (let i = 0; i < balance; i++) {
                const tokenId = await contract.tokenOfOwnerByIndex(account, i);
                const claimableAmount = await contract.claimablePayout(tokenId);
                totalClaimable = totalClaimable.add(claimableAmount);
            }

            setClaimableTokens(totalClaimable);
        }
    };

    useEffect(() => {
        fetchClaimableTokens();
    }, [contract, account]);

    const handleClaim = async () => {
        if (contract && account) {
            const signer = provider.getSigner();
            const contractWithSigner = contract.connect(signer);

            try {
                const tx = await contractWithSigner.claimAll();
                await provider.waitForTransaction(tx.hash);
                fetchClaimableTokens();
                alert(`Tokens claimed successfully! Transaction hash: ${tx.hash}`);
            } catch (error) {
                console.error('Error claiming tokens:', error);
            }
        }
    };

    // Tooltip message for the users
    const tooltipMessage = "Please note: Some Moons may not be eligible to claim from due to their incubation period. If no Souls are claimable you will waste gas. ";

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {claimableTokens !== null && (
                <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                    <CenteredText>Total Claimable tokens: {parseFloat(ethers.utils.formatEther(claimableTokens)).toFixed(9)}</CenteredText>
                    <Tooltip title={tooltipMessage} placement="top">
                        <ClaimButton onClick={handleClaim}>Claim All Tokens</ClaimButton>
                    </Tooltip>
                </div>
            )}
        </div>
    );
};

export default VestingClaim;

export const ClaimButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  width: 220px;
  min-width: 160px;
  height: 40px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: ${Transitions.all};
  margin-top: 10px;
  @media (max-width: 768px) {
display:none;

}

  &:hover,
  &:focus {
    background-color: #91edd9ff;
    color: #000000;
    border: 2px solid #faf9cdff;
    border-radius: ${BorderRad.m};
  }
`;
