import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ethers } from 'ethers';
import { useWallet } from '../WalletContext';
import MunContractABI from "../abis/MUN.json"; // Replace with your actual MunContract ABI
import { MUNContractAddress } from './ContractAddress'; // Ensure this points to your MunContract

const Gallery = () => {
  const { provider, account } = useWallet();
  const [tokenCounts, setTokenCounts] = useState({}); // Object to store token counts
  const [tokenImages, setTokenImages] = useState({}); // Object to store unique token images

  const fetchOwnedTokens = async () => {
    if (!provider || !account) return;

    const contract = new ethers.Contract(MUNContractAddress, MunContractABI.abi, provider);

    try {
      const tokenIds = await contract.getOwnedTokenIds(account);
      console.log("Token IDs:", tokenIds);

      const tokenCountsObj = {}; // Object to store token counts
      const tokenImagesObj = {}; // Object to store unique token images

      // Fetch metadata for each token
      for (const tokenId of tokenIds) {
        try {
          const metadata = await contract.tokenURI(tokenId);
          const jsonData = JSON.parse(decodeURIComponent(metadata.substring(metadata.indexOf(',') + 1)));
          const imageUrl = jsonData.image;
          tokenCountsObj[imageUrl] = (tokenCountsObj[imageUrl] || 0) + 1;
          tokenImagesObj[imageUrl] = imageUrl;
        } catch (error) {
          console.error("Error parsing JSON for token ID:", tokenId, error);
        }
      }

      setTokenCounts(tokenCountsObj);
      setTokenImages(tokenImagesObj);
    } catch (error) {
      console.error("Failed to fetch owned tokens:", error);
    }
  };

  useEffect(() => {
    fetchOwnedTokens();
  }, [account, provider]);

  useEffect(() => {
    const updateTokensOnTransfer = async () => {
      if (!provider || !account) return;

      provider.on('block', () => {
        fetchOwnedTokens();
      });

      return () => {
        provider.removeAllListeners('block');
      };
    };

    updateTokensOnTransfer();
  }, [account, provider]);

  console.log("Token Counts:", tokenCounts);
  console.log("Token Images:", tokenImages);

  return (
    <GalleryContainer>
      {Object.entries(tokenImages).map(([imageUrl, _], index) => (
        <div key={imageUrl}>
          <TokenImage src={imageUrl} alt={`Token Image`} />
          <TokenCount>{`x${tokenCounts[imageUrl]}`}</TokenCount>
        </div>
      ))}
    </GalleryContainer>
  );
};

const GalleryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 2fr);
  grid-gap: 20px;
`;

const TokenText = styled.span`
  display: block;
  margin: 10px;
  color: white;
`;

const TokenImage = styled.img`
  display: block;
  margin: 10px auto;
  max-width: 200px;
`;

const TokenCount = styled.span`
  display: block;
  margin-top: 5px;
  color: white;
  text-align: center;
`;

export default Gallery;
