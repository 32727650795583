import React from 'react';
import './css/HomePage.css';
import styled from 'styled-components';
import ContractInteraction from '../components/ContractInteraction';


const HomePage = () => {
    return (
        <>
            <TitleBox className="title-container">
                <h1>Arcanum Dashboard</h1>
            </TitleBox>
            <StyledDiv>
                <ContractInteraction />
                {/* Add your content here */}
            </StyledDiv>
        </>
    );
};

export default HomePage;

const TitleBox = styled.div`
{
    margin-top: 120px;
        color: white;

  }
`;

const StyledDiv = styled.div`
     width: 65%;
    height: 570px;
    /* Add 600px to the existing height */
    margin: 0px auto 0;
    /* Add 50px to the top margin */
    padding: 20px;
    border: 1px solid #ffffff;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.9);
    background-color: #000000;
    color: #c3c3c3;


  @media (max-width: 768px) {
         width: 95%;
 border: 0px solid #ffffff;
     background-color: transparent;
     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.0);

  }
`;